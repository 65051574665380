<!-- 警告提示弹窗 -->
<template>
  <a-modal
    v-model:visible="isDialogAddVisible"
    :footer="false"
    :header="false"
    width="310px"
    :closable="false"
  >
    <div class="dialog-tips">
      <img :src="getImage('tip')" alt="tip" />
      <p v-show="title" class="tit">{{ title }}</p>
      <p v-if="message !== ' '" class="mess">{{ message }}</p>
      <slot name="text"></slot>
      <slot name="tiptext"></slot>
      <div class="footer-btn">
        <a-button size="large" @click="handleCancel">{{
          dialogCloseBtnText || $t('address.batchaddaddress.5g5at6')
        }}</a-button>
        <a-button
          type="primary"
          :loading="loading"
          size="large"
          @click="handleSuccess"
          >{{ dialogSuccessBtnText || $t('fish.changefish.dteu66') }}</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
  import { ref, toRefs, watch } from 'vue';
  import usePub from '@/utils/pub-use';

  const getImage = usePub;
  const props = defineProps({
    dialogAddVisible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '确认要删除该设备吗?',
    },
    message: {
      type: String,
      default: '禁用后，请在确保账户安全的情况下再解禁您的账户.',
    },
    dialogCloseBtnText: {
      type: String,
      default: '',
    },
    dialogSuccessBtnText: {
      type: String,
      default: '',
    },
    flag: {
      type: Number,
    },
  });

  const refProps = toRefs(props);
  const emits = defineEmits(['update:dialogAddVisible', 'myClick']); // 此处需写'update'
  const isDialogAddVisible = ref(false);
  watch(refProps.dialogAddVisible, (val, old) => {
    isDialogAddVisible.value = val;
  });
  watch(isDialogAddVisible, (val, old) => {
    emits('update:dialogAddVisible', val); // 此处的update亦要写
  });

  const handleCancel = () => {
    emits('myClick', false);
    isDialogAddVisible.value = false;
  };

  const handleSuccess = () => {
    emits('myClick', true);
    isDialogAddVisible.value = false;
  };
</script>

<style lang="less" scoped>
  .dialog-tips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 68px;
      height: 68px;
    }
    .tit {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
    }
    .mess {
      width: 100%;
      margin-bottom: 15px;
      font-size: 13px;
      color: var(--color-text-1);
      text-align: center;
    }
    .footer-btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 12px;
      button {
        width: 48%;
        height: 40px;
        border-radius: 5px;
      }
    }
  }
</style>
