<template>
  <div class="content">
    <a-spin :loading="loading"  :style="{ width: '100%' }">
      <div class="left-pc">
        <a-form
          ref="formRef"
          :model="form"
          :style="{ width: '100%', marginBottom: '8px' }"
          @submit-success="handleToNext"
        >
          <!-- 谷歌 -->
          <p
            v-if="OTPList.method === OTP_CODE && payType !== 'OTP_CODE'"
            class="code-title"
          >
            {{ $t(`ver.${OTPList.method}`) }}
            <icon-check-circle v-if="isYesList.OTP_CODE === 1" />
            <icon-close-circle v-else-if="isYesList.OTP_CODE === 2" />
          </p>
          <a-form-item
            v-if="OTPList.method === OTP_CODE && payType !== 'OTP_CODE'"
            field="OTP_CODE"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input v-model="form.OTP_CODE"> </a-input>
            <template #extra>
              <p class="tip">
                {{ $t('safetydialog.index.284434') }}{{ $t(`ver.${OTP_CODE}`)
                }}{{ $t('safetydialog.index.uo4t31') }}
              </p>
            </template>
          </a-form-item>
          <!-- 邮箱 -->
          <p
            v-if="emailList.method === 'EMAIL' && payType !== 'EMAIL'"
            class="code-title"
          >
            {{ $t(`ver.${emailList.method}_CODE`) }}
            <icon-check-circle v-if="isYesList.EMAIL === 1" />
            <icon-close-circle v-else-if="isYesList.EMAIL === 2" />
          </p>
          <a-form-item
            v-if="emailList.method === 'EMAIL' && payType !== 'EMAIL'"
            field="EMAIL"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input v-model="form.EMAIL">
              <template #suffix>
                <!-- 邮箱验证码逻辑 -->
                <div>
                  <a-button
                    v-if="timeData.hideCodeEmail"
                    type="primary"
                    class="codeBox"
                    @click="handleSendCode('EMAIL')"
                  >
                    {{ $t('safetydialog.index.zj74uq') }}
                  </a-button>
                  <a-button v-else disabled class="codeBox" type="primary">
                    <a-countdown
                      :value="timeData.emailNow + 1000 * 60"
                      :now="timeData.emailNow"
                      format="s"
                      @finish="handleFinish('EMAIL')"
                    />
                    s{{ $t('forget.step2.t728co') }}
                  </a-button>
                </div>
              </template>
            </a-input>
            <template #extra>
              <p class="tip"
                >{{ $t('safetydialog.index.284434')
                }}{{ $t(`ver.${emailList.method}`) }}{{ emailList.account
                }}{{ $t('safetydialog.index.4yhgsc') }}</p
              >
            </template>
          </a-form-item>
          <!-- 手机号 -->
          <p
            v-if="phoneList.method === 'PHONE' && payType !== 'PHONE'"
            class="code-title"
          >
            {{ $t(`ver.${phoneList.method}_CODE`) }}
            <icon-check-circle v-if="isYesList.PHONE === 1" />
            <icon-close-circle v-else-if="isYesList.PHONE === 2" />
          </p>
          <a-form-item
            v-if="phoneList.method === 'PHONE' && payType !== 'PHONE'"
            field="PHONE"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input v-model="form.PHONE">
              <template #suffix>
                <!-- 手机验证码逻辑 -->
                <div>
                  <a-button
                    v-if="timeData.hideCodePhone"
                    type="primary"
                    class="codeBox"
                    @click="handleSendCode('PHONE')"
                  >
                    {{ $t('safetydialog.index.zj74uq') }}
                  </a-button>
                  <a-button v-else disabled class="codeBox" type="primary">
                    <a-countdown
                      :value="timeData.phoneNow + 1000 * 60"
                      :now="timeData.phoneNow"
                      format="s"
                      @finish="handleFinish('PHONE')"
                    />
                    {{ $t('forget.step2.t728co') }}
                  </a-button>
                </div>
              </template>
            </a-input>
            <template #extra>
              <p class="tip"
                >{{ $t('safetydialog.index.284434')
                }}{{ $t(`ver.${phoneList.method}`) }}{{ phoneList.account
                }}{{ $t('safetydialog.index.4yhgsc') }}</p
              >
            </template>
          </a-form-item>
          <a-form-item v-if="!loading" hide-label>
            <a-button
              class="subBtn"
              html-type="submit"
              :loading="isLoading"
              type="primary"
            >
              {{ $t('public.submit') }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive } from 'vue';
  import { verificationList, verifyCode, sendCode } from '@/api/verification';
  import { useI18n } from 'vue-i18n';
  import { Notification } from '@arco-design/web-vue';

  const { t } = useI18n();
  const props = defineProps({
    ident: {
      type: String,
    },
    payType: {
      type: String,
    },
  });

  const emits = defineEmits(['confirmClick']); // 此处需写'update'
  const safeVisible = ref(false);

  const validateGetCode = (value: any, callback: any) => {
    const regEmailPass = /^\d{6}$/;
    if (!regEmailPass.test(value)) {
      callback(t('safetydialog.index.wks78g'));
    } else {
      callback();
    }
  };

  const form = reactive<any>({});
  const OTP_CODE = 'OTP_CODE'; // 谷歌验证器标识

  // 获取支持的双因素验证列表
  const OTPList = reactive<any>({});
  const emailList = reactive<any>({});
  const phoneList = reactive<any>({});
  const allPayType = ref<any>([]);

  const loading = ref(true);
  const getVerificationList = () => {
    verificationList().then((res: any) => {
      if (res.code === '0000') {
        loading.value = false;
        allPayType.value = res.data.methods
          .filter(
            (item: any) =>
              item.method !== 'PIN_CODE' && item.method !== props.payType
          )
          .map((item: any) => item.method);

        res.data.methods.forEach((item: any) => {
          // if (item.method !== 'PIN_CODE') {
          //   allPayType.push(item.method);
          // }
          if (item.method === OTP_CODE) {
            Object.assign(OTPList, item);
          }
          if (item.method === 'EMAIL') {
            Object.assign(emailList, item);
          }
          if (item.method === 'PHONE') {
            Object.assign(phoneList, item);
          }
        });
      }
    });
  };
  getVerificationList();

  // pin码的确认按钮
  const isLoading = ref(false);
  // 发送验证码
  const timeData = reactive<any>({
    hideCodeEmail: true,
    hideCodePhone: true,
    // emailNow: undefined,
    // phoneNow: '',
    sessionIdEmail: '',
    sessionIdPhone: '',
  });
  const handleSendCode = (type: string) => {
    const isEmail = type === 'EMAIL';

    timeData[isEmail ? 'hideCodeEmail' : 'hideCodePhone'] = false;
    timeData[isEmail ? 'emailNow' : 'phoneNow'] = Date.now();

    sendCode({
      receiverType: type,
      source: props?.ident,
    }).then((res: any) => {
      if (res.code === '0000') {
        timeData[isEmail ? 'sessionIdEmail' : 'sessionIdPhone'] = res.data;
      }
    });
  };

  const handleFinish = (type: 'EMAIL' | 'PHONE') => {
    timeData[type === 'EMAIL' ? 'hideCodeEmail' : 'hideCodePhone'] = true;
  };

  // 安全验证的确认按钮
  const allTokensArr = ref<any>([]);
  const isYesList = reactive<any>({
    OTP_CODE: 0,
    EMAIL: 0,
    PHONE: 0,
  });
  const isYesListDel = reactive<any>({ ...isYesList });
  const formRef = ref();

  const handleToNext = async () => {
    const verificationPromises = allPayType.value.map((method: any) => {
      if (isYesList[method] === 1) {
        return Promise.resolve(null);
      }

      let sessionId: string | null | undefined;
      let code: string | undefined;

      if (method === 'EMAIL') {
        sessionId = timeData.sessionIdEmail;
        code = form.EMAIL;
      } else if (method === 'PHONE') {
        sessionId = timeData.sessionIdPhone;
        code = form.PHONE;
      } else if (method === 'OTP_CODE') {
        sessionId = null;
        code = form.OTP_CODE;
      } else {
        return Promise.resolve(null); // 其他未支持的类型直接返回 null
      }

      if (method !== 'OTP_CODE' && !sessionId) {
        Notification.error({
          content: t(`code.index.${method}`),
          duration: 3000,
        });
        isYesList[method] = 2;
        return Promise.resolve(null);
      }

      return verifyCode({
        sessionId,
        code,
        method,
        source: props?.ident,
      }).then((res: any) => {
        if (res.code === '0000') {
          isYesList[method] = 1; // 通过验证
          return { method, token: res.data };
        }
        isYesList[method] = 2; // 失败
        return null;
      });
    });

    const results = await Promise.all(verificationPromises);

    if (results && results.length > 0) {
      results.forEach((item) => {
        if (item !== null) {
          allTokensArr.value.push(item);
        }
      });
    }

    const tokens = allTokensArr.value.map((item: any) => item.token);
    console.log(tokens, 'tokens');

    if (allTokensArr.value.length === allPayType.value.length) {
      emits('confirmClick', tokens);
    }
  };

  // 关闭弹窗清空输入框
  const handleCloseModal = (v: string) => {
    safeVisible.value = false;
    allTokensArr.value.length = 0;
    Object.assign(isYesList, isYesListDel);
    formRef.value.resetFields();
    formRef.value.clearValidate();
  };

  const isResetForm = () => {
    allTokensArr.value.length = 0;
    Object.assign(isYesList, isYesListDel);
    formRef.value.resetFields();
    formRef.value.clearValidate();
  };

  defineExpose({
    handleCloseModal,
    isResetForm,
  });
</script>

<style lang="less" scoped>
  .arco-input-wrapper {
    width: 100%;
    height: 48px;
    background: var(--color-bg-1);
    border: 1px solid var(--color-neutral-2);
  }
  .arco-input-focus {
    border: 1px solid rgba(16, 147, 255, 1);
  }
  .content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .left-pc {
      width: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -10px;
        height: 60px;
        .arco-icon-close {
          cursor: pointer;
          font-size: 18px;
          color: var(--color-neutral-8);
        }
      }
      p {
        color: var(--color-neutral-8);
      }
      .tip {
        margin: 0 0 28px 0;
        font-size: 12px;
      }
      button {
        width: 100%;
        height: 48px;
      }
      .subBtn {
        margin-top: 40px;
      }
      h1 {
        margin-bottom: 32px;
      }
      .code-title {
        font-size: 15px;
        color: var(--color-neutral-10);
        font-weight: 550;
      }
      .goRegister {
        color: rgba(16, 147, 255, 1);
        cursor: pointer;
        // margin-top: 10px;
        margin-bottom: 18px;
      }
    }
    .right-qrCode {
      width: 180px;
    }
    .codeBox {
      min-width: 120px;
      height: 100%;
      cursor: pointer;
      position: relative;
      left: 12px;
      border-radius: 3px;
    }
  }
  :deep(.arco-form-item) {
    margin-bottom: -10px !important;
  }
  :deep(.arco-statistic-value) {
    font-size: 14px !important;
    color: var(--color-bg-white) !important;
  }
  .arco-icon-check-circle {
    color: rgb(var(--green-5));
  }
  .arco-icon-close-circle {
    color: rgb(var(--red-5));
  }
</style>
