import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import { ref } from 'vue';
import { appRoutes } from './routes';
import { REDIRECT_MAIN, NOT_FOUND_ROUTE } from './routes/base';
import createRouteGuard from './guard';

NProgress.configure({ showSpinner: true }); // NProgress Configuration

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: 'home',
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/home/index.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: '/verifying',
    //   name: 'Verifying',
    //   component: () => import('@/views/demo/index.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login/indexNew.vue'), // 需要改
      meta: {
        requiresAuth: false,
        title: 'login.form.login',
      },
    },
    {
      path: '/stepVerification',
      name: 'StepVerification',
      component: () => import('@/views/login/verification.vue'), // 需要改
      meta: {
        requiresAuth: false,
        title: 'login.form.login',
      },
    },
    {
      path: '/resigter',
      name: 'Resigter',
      component: () => import('@/views/resigter/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'register.form.register',
      },
    },
    {
      path: '/registerVer',
      name: 'RegisterVer',
      component: () => import('@/views/resigter/verification.vue'),
      meta: {
        requiresAuth: false,
        title: 'register.form.register',
      },
    },
    {
      path: '/forgetPassword',
      name: 'ForgetPassword',
      component: () => import('@/views/forget/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'login.forget',
      },
    },
    {
      path: '/serviceAgreement',
      name: 'ServiceAgreement',
      component: () => import('@/views/login/service.vue'),
      meta: {
        requiresAuth: false,
        title: 'login.form.ServiceAgreement',
      },
    },
    {
      path: '/privacyPolicy',
      name: 'PrivacyPolicy',
      component: () => import('@/views/login/privacy.vue'),
      meta: {
        requiresAuth: false,
        title: 'login.form.PrivacyPolicy',
      },
    },
    {
      path: '/simple',
      name: 'ErmsSimple',
      component: () => import('@/views/Financial/components/simple.vue'),
      meta: {
        requiresAuth: false,
        title: 'components.subscriptionmodal.7njv30',
      },
    },
    {
      path: '/down',
      name: 'Down',
      component: () => import('@/views/down/index.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/activity',
      name: 'Activity',
      component: () => import('@/views/activity/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'navbar.index.fsd231',
      },
    },
    {
      path: '/financial',
      name: 'Financial',
      component: () => import('@/views/Financial/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'financial.index.a416j6',
      },
    },
    {
      path: '/financialRecords',
      name: 'FinancialRecords',
      component: () => import('@/views/Financial/components/history.vue'),
      meta: {
        requiresAuth: false,
        title: 'financial.index.a416j6',
      },
    },
    {
      path: '/financialInfo',
      name: 'FinancialInfo',
      component: () => import('@/views/Financial/components/historyInfo.vue'),
      meta: {
        requiresAuth: false,
        title: 'financial.index.a416j6',
      },
    },
    {
      path: '/redemptionRecords',
      name: 'RedemptionRecords',
      component: () => import('@/views/Financial/components/historyRed.vue'),
      meta: {
        requiresAuth: false,
        title: 'financial.index.a416j6',
      },
    },
    {
      path: '/enforcement',
      name: 'Enforcement',
      component: () => import('@/views/enforcement/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'enforcement.index.glq4x4',
      },
    },
    {
      path: '/disclosure',
      name: 'Disclosure',
      component: () => import('@/views/enforcement/components/disclosure.vue'),
      meta: {
        requiresAuth: false,
        title: 'enforcement.index.glq4x4',
      },
    },
    {
      path: '/waitingSubmit',
      name: 'WaitingSubmit',
      component: () => import('@/views/enforcement/components/waiting.vue'),
      meta: {
        requiresAuth: false,
        title: 'enforcement.index.glq4x4',
      },
    },
    {
      path: '/legalInformation',
      name: 'LegalInformation',
      component: () => import('@/views/enforcement/components/formBox.vue'),
      meta: {
        requiresAuth: false,
        title: 'enforcement.index.glq4x4',
      },
    },
    {
      path: '/qrRouter',
      name: 'QrRouter',
      component: () => import('@/views/qrRouter/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'BasicEx',
      },
    },
    // {
    //   path: '/unblockacc',
    //   name: 'Unblockacc',
    //   component: () => import('@/views/unblockacc/index.vue'),
    //   meta: {
    //     requiresAuth: false,
    //   },
    // },
    {
      path: '/submit',
      name: 'submit',
      component: () => import('@/views/unblockacc/submit.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    // @ts-ignore
    ...appRoutes,
    // @ts-ignore
    REDIRECT_MAIN,
    // @ts-ignore
    NOT_FOUND_ROUTE,
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

createRouteGuard(router);

export default router;
