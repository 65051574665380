import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Message, Modal, Notification } from '@arco-design/web-vue';
import { useUserStore } from '@/store';
import {
  isLogin,
  getToken,
  getTokenDev,
  isLoginDev,
  clearToken,
  setToken,
} from '@/utils/auth';
import { useCookies } from 'vue3-cookies';
import { ClientJS } from 'clientjs';

const { cookies } = useCookies();

const client = new ClientJS();
export interface HttpResponse<T = unknown> {
  status: number;
  msg: string;
  code: number;
  data: T;
}
if (import.meta.env.VITE_API_BASE_URL) {
  axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
}
// 跨域
axios.defaults.withCredentials = true;

// 语言
axios.defaults.headers.common['Accept-Language'] = `${
  localStorage.getItem('arco-locale') || 'en'
};q=0.9`;

// 时区
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
axios.defaults.headers.common['X-Timezone'] = timezone || '';

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // let each request carry token
    // this example using the JWT token
    // Authorization is a custom headers key
    // please modify it according to the actual situation
    const clientId = 'web.wallet';
    const clientSecret = 'rgcmYxq68wgafn7xsiePUkfqGvtBkCQw';
    const token = getToken();
    const tokenDev = getTokenDev();

    // @ts-ignore
    config.headers['X-basicex-options-device-id'] = client.getFingerprint();
    if (process.env.NODE_ENV === 'production') {
      if (!config.headers) {
        config.headers = {};
      }
      if (token === 'token') {
        config.headers.Authorization = `Basic ${btoa(
          `${clientId}:${clientSecret}`
        )}`;
      } else if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } else if (process.env.NODE_ENV !== 'production') {
      if (!config.headers) {
        config.headers = {};
      }
      if (tokenDev === 'token') {
        config.headers.Authorization = `Basic ${btoa(
          `${clientId}:${clientSecret}`
        )}`;
      } else if (tokenDev) {
        config.headers.Authorization = `Bearer ${tokenDev}`;
      }
    }

    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === '0010') {
      if (process.env.NODE_ENV === 'production') {
        setToken('', '1s');
        window.location.href = '/login';
      }
    }

    if (res.code === '403') {
      clearToken();
      window.location.href =
        process.env.NODE_ENV === 'production' ? '/login' : '#/login';
    }

    if (res.access_token) {
      return res;
    }
    if (res.code === '0000') {
      if (res.message !== '') {
        Notification.success({
          content: res.message,
          duration: 3000,
        });
      }
      return res;
    }
    // 图片上传阿里判断
    if (
      response.status !== 204 &&
      res.code !== '0010' &&
      res.code !== '202020' &&
      res.code !== '403' &&
      res.message
    ) {
      Notification.error({
        content: res.message,
        duration: 3000,
      });
    }
    return res;
  },
  (error) => {
    const status = (error.response && error.response.status) || '';
    const data = (error.response && error.response.data) || {};

    if (status === 401) {
      // Message.error({
      //   content: '登录过期, 请重新登录',
      //   duration: 5 * 1000,
      // });
      clearToken();
      window.location.href =
        process.env.NODE_ENV === 'production' ? '/login' : '#/login';
    }
    if (status === 403) {
      // Message.error({
      //   content: '登录过期, 请重新登录',
      //   duration: 5 * 1000,
      // });

      clearToken();
      window.location.href =
        process.env.NODE_ENV === 'production' ? '/login' : '#/login';
    }
    if (status === 424) {
      Message.error({
        content: data.message,
        duration: 5 * 1000,
      });
      clearToken();
      window.location.href =
        process.env.NODE_ENV === 'production' ? '/login' : '#/login';
    }
    if (status === 404) {
      Message.error({
        content: '接口404报错',
        duration: 5 * 1000,
      });
    }
    if (status === 500 || status === 503) {
      Message.error({
        content: '服务器错误',
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error);
  }
);
