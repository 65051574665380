export default {
  'overview.index.y55nvv': '您尚未通過身份認證！',
  'overview.index.vm3ll4': '立即認證',
  'overview.index.7630kj': '恭喜您，已經通過初級身份認證！',
  'overview.index.6gv796': '恭喜您，已經通過高級身份認證！',
  'overview.index.w42owo': '總資產估值',
  'overview.index.nbd725':
    '您的帳戶下目前沒有任何資產，請先完成身份認證然後再為帳戶儲值。',
  'overview.index.6b7683': '您的帳戶下目前沒有任何資產，請先儲值。',
  'overview.index.49nwm5': '買币',
  'overview.index.d48b5i': '一鍵購買加密貨幣',
  'overview.index.1850v5': '快捷區/自選區任意選擇',
  'overview.index.9t7e25': '立即購買',
  'overview.index.fd5w2s': '儲值',
  'overview.index.r376u3': '已持有貨幣儲值到幣趣',
  'overview.index.66x2y5': '從外部錢包提幣至幣趣',
  'overview.index.d0et7a': '立即儲值',
  'overview.index.y774k3': '收款',
  'overview.index.2yn505': '從其他幣趣帳戶接收轉帳',
  'overview.index.8ybxdp': '0手續費收/付款',
  'overview.index.b46qtd': '立即收款',
  'components.assets-value.18yr1j': '凍結金額:',
  'components.assets-value.2b3517': '可用余額:',
  'components.assets-value.8478m6': '轉帳',
  'components.assets-value.687r4q': '提現',
  'components.assets-value.w22527': '日',
  'components.assets-value.ge43pz':
    '我們將在每日的香港時間16點之前更新資產數據。',
  'components.assets-value.zghh33': '收入',
  'components.assets-value.a1dr9x': '支出',
  'components.assets-value.ly1k52': '凍結金額包含待結算金額與系統凍結金額',
  'components.assets-value.j70i73': '向幣趣用戶發送加密貨幣，享0手續費',
  'components.assets-value.jj3hg4': '收入',
  'components.assets-value.i6j148': '支出',
  'components.header-info.12t323': '幣趣ID',
  'components.header-info.um3476': '用戶類型',
  'components.header-info.4m03sm': '上次登錄時間',
  'components.header-info.3787t3':
    '請注意：昵稱僅支持更改一次，請在更改前務必仔細確認！',
  'components.header-info.pj4n5u': '未認證',
  'components.header-info.zqw278': '初級認證',
  'components.header-info.jd9962': '高級認證',
  'components.header-info.43h887': '個人用戶',
  'components.header-info.yqi9ko': '企業用戶',
  'components.header-info.e2tp8h': '編輯昵稱',
  'components.header-info.1f7mrx': '為您的個人資料設置自定義昵稱。',
  'components.header-info.5noe2d': '昵稱',
  'components.header-info.p63d76': '請輸入您要修改的昵稱',
  'components.header-info.xwpc25': '昵稱不能為空',
  'components.revence-data.9713y6': '收支詳情',
  'components.revence-data.npd4wy': '更多',
  'components.revence-data.ce4131': '提幣申請已提交',
  'components.revence-data.9752t3': '系統處理中',
  'components.revence-data.62xf33': '已完成',
  'components.revence-data.2iv5ux': '狀態',
  'components.revence-data.3y5g5i': '幣種',
  'components.revence-data.4wmme1': '提現金額',
  'components.revence-data.tyfp3z': '手續費',
  'components.revence-data.s8967j': '轉帳網絡',
  'components.revence-data.do87js': '可用余額',
  'components.revence-data.q5vlq3': '凍結金額',
  'components.revence-data.46f9tr': '地址',
  'components.revence-data.tle9fw':
    '請注意，我們會在提幣完成時給你發送郵件通知，為什麼我的提現',
  'components.revence-data.zfuugj': '還沒到賬？',
  'components.revence-data.rfkndq': '金額',
  'components.revence-data.61xy2u': '從',
  'components.revence-data.354789': '至',
  'components.revence-data.2uv9jf': '創建時間',
  'components.revence-data.rfyt9g': '訂單編號',
  'components.revence-data.3hb0uk': '備註',
  'components.revence-data.35h0kp': '成功',
  'components.revence-data.7h5jsb': '時間',
  'components.revence-data.j8k864': '儲值數量',
  'components.revence-data.543v48': '處理中',
  'components.revence-data.5s4r08': '審批中',
  'components.revence-data.dyxzu8': '已取消',
  'components.revence-data.868ebs': '失敗',
  'components.revence-data.ltoesk': '待付款',
  'components.revence-data.g1qbzi': '已付款，待確認',
  'components.revence-data.kq9g21': '申訴中',
  'components.revence-data.jfjmj4': '暫無數據',
  'components.revence-data.268t86': '儲值詳情',
  'components.revence-data.k51q8g': '提現詳情',
  'components.revence-data.4l1jq3': '轉帳詳情',
  'components.revence-data.9sc1cn': '類型',
  'components.revence-data.hpr4p5': '數量',
  'accaction.index.87m503': '帳戶安全',
  'accaction.index.5565bk': '近期帳戶活動',
  'accaction.index.p55715': '帳戶異常？',
  'accaction.index.v7gjvv': '禁用帳戶',
  'accaction.index.y3gm71': '登錄活動',
  'accaction.index.ey2758': '安全操作記錄',
  'accaction.index.qznrs3': '來源',
  'accaction.index.75e7l9': 'IP地址',
  'address.batchaddaddress.6b7h2u': '批量添加地址',
  'address.batchaddaddress.lx0837': '添加',
  'address.batchaddaddress.5g5at6': '取消',
  'address.batchaddaddress.0c092d': '保存',
  'address.batchaddaddress.7u2hjf': '您填寫的地址尚未保存，是否要取消保存。',
  'address.batchaddaddress.s47gk6': '繼續編輯',
  'address.batchaddaddress.s319i3': '地址備註',
  'address.batchaddaddress.kttf6p': '地址來源',
  'address.batchaddaddress.qe79l9': '操作',
  'address.batchaddaddress.8313nb': '無需填寫',
  'address.index.6p7di6': '地址管理',
  'address.index.1n20p9': '白名單限制已關閉',
  'address.index.59zoxy': '白名單設置',
  'address.index.af4395': '全部',
  'address.index.tf8k5s': '白名單',
  'address.index.pqk179': '搜索',
  'address.index.jrqg1h': '批量導出',
  'address.index.xih4ay': '添加地址',
  'address.index.t6l0wx': '已選中',
  'address.index.o0w7e4': '個地址，您希望：',
  'address.index.39qj58': '加入白名單',
  'address.index.83dxt5': '移除白名單',
  'address.index.s01fov': '刪除',
  'address.index.80651m': '添加提現地址',
  'address.index.86kz67': '保存為通用地址，可以適用於多個幣種提現',
  'address.index.vwcg4v': '白名單地址',
  'address.index.n6u1h8':
    '開啟白名單功能後，您將只能向白名單地址列表中的地址提幣。',
  'address.index.5tmj7t': '地址搜索',
  'address.index.u5b9jk': '非白名單地址',
  'address.index.x4cqzf': '不能為空！',
  'address.index.2k9010': '請輸入4-20個字符的備註，例如：小明的幣趣帳戶',
  'address.index.eii4u3': '提幣地址',
  'address.index.bdqqx0': '請輸入提幣地址',
  'address.index.871rhx':
    '開啟白名單後將限制您的帳戶只能向白名單地址提幣，從而有助於保護您的資產安全。',
  'address.index.31c20i': '您確定要刪除該地址嗎？',
  'address.index.7uq2y9': '地址簿',
  'address.index.r539eg': '轉帳網絡不能為空',
  'address.index.kw2845': '通用地址',
  'address.index.fp19tw': '普通地址',
  'changepassword.index.u8w3oi': '修改密碼',
  'changepassword.index.8473bq': '提交',
  'changepassword.index.6qts82': '密碼修改成功',
  'changepassword.index.7bh14e': '您的密碼已修改，請使用新密碼登錄',
  'changepassword.index.awho4e': '登錄',
  'changepassword.index.157jed': '舊密碼',
  'changepassword.index.2rye5l': '請輸入',
  'changepassword.index.7yf04o': '新密碼',
  'changepassword.index.51j37z': '確認新密碼',
  'changepassword.index.6382f8':
    '密碼需要8位以上字符組成，必須包含數字及字母大小寫',
  'changepassword.index.o2u857': '兩次密碼不一致',
  'changepassword.index.71mx3u': '密碼錯誤!',
  'device.index.338736': '設備:',
  'device.index.6f1n27': '設備管理',
  'device.index.b30ayt': '這些設備已被授權允許登錄您的帳戶',
  'device.index.r0pnkc': '設備',
  'device.index.3o1ncj': '登錄所在地',
  'disableacc.index.i6lc27': '帳戶活動',
  'disableacc.index.e6oy6i': '禁用您的帳戶將導致以下結果：',
  'disableacc.index.o45g18':
    '如果您決定重新激活您的帳戶，請在禁用兩個小時後登錄帳戶進行解禁流程',
  'disableacc.index.u61qfr': '删除此帳戶',
  'disableacc.index.t3d7tt': '確認要删除該帳戶嗎?',
  'disableacc.index.sk863i': '所有交易和登錄功能都將禁用',
  'disableacc.index.893l4o': '刪除所有信任設備',
  'disableacc.index.56bm0l': '所有待處理的提現將被取消',
  'disableacc.index.n4932t': '所有的當前委託都將被取消',
  'disableacc.index.803x4k': '已驗證的身份信息不會被刪除',
  'email.bindemail.6oti7n': '綁定郵箱驗證',
  'email.bindemail.77it3v': '獲取驗證碼',
  'email.bindemail.n0705g': 's 後獲取',
  'email.bindemail.2k8gfj': '綁定郵箱驗證碼',
  'email.bindemail.6275ov': '新郵箱',
  'email.bindemail.vx440f': '郵箱號不能為空!',
  'email.bindemail.4r361n': '請輸入新郵箱號碼',
  'email.bindemail.5y253x': '郵箱驗證碼',
  'email.bindemail.d7l1pb': '郵箱驗證碼不能為空！',
  'email.bindemail.q0840y': '請輸入新郵箱驗證碼',
  'email.changeemail.rm3mkt': '更改郵箱驗證',
  'email.changeemail.sui27i': '更改郵箱驗證碼',
  'email.changeemail.fpep15': '新郵箱驗證碼',
  'email.unbindemail.igv777': '解綁郵箱驗證',
  'email.unbindemail.1467zm': '安全驗證',
  'email.unbindemail.6un89v': '返回',
  'fish.changefish.09en1y': '更改防釣魚碼',
  'fish.changefish.1f4oi3': '釣魚碼會出現在哪？',
  'fish.changefish.x47u89': '請輸入 4-10 個字符，不包括特殊符號。',
  'fish.changefish.dteu66': '確定',
  'fish.changefish.x30e85':
    '不要將您的密碼、谷歌或短信驗證碼告訴任何人，包括幣趣客服。',
  'fish.changefish.545pu5': '舊防釣魚碼',
  'fish.changefish.lkyri4': '新防釣魚碼',
  'fish.changefish.bry0ys': '不能包含中文和特殊字符！',
  'fish.changefish.go8188': '需要4-20位以上字符組成',
  'fish.setfinsh.61569g': '設置防釣魚碼',
  'fish.setfinsh.o8230x': '什麼是防釣魚碼？',
  'fish.setfinsh.6t45bq':
    '防釣魚碼是你自己設置的一串字符，能夠幫助你識別防冒幣趣的網站或者郵件',
  'fish.setfinsh.7cf26c': '防釣魚碼會出現在哪？',
  'fish.setfinsh.1rske9':
    '設置好防釣魚碼後，每一封幣趣發給您的郵件都會帶有這串字符。',
  'fish.setfinsh.29c19t': '創建防釣魚碼',
  'fish.setfinsh.bk3ekb': '防釣魚碼',
  'components.alert.172xn1':
    '為了保護您的資產安全，更改或解綁驗證後該帳戶將禁用支付服務、提幣和C2C賣幣24小時。',
  'google.bindgoogle.bj1319': '綁定谷歌驗證',
  'google.bindgoogle.t70ckm': '下載並安裝身份驗證器App',
  'google.bindgoogle.1c6pys': '如何使用谷歌身份驗證器?',
  'google.bindgoogle.y8n99f': '下一步',
  'google.bindgoogle.7v5exp': '用谷歌驗證App掃描這個二維碼',
  'google.bindgoogle.orfs9q':
    '如果您無法掃描這個二維碼，請在App中手動輸入這串字符',
  'google.bindgoogle.cl439i': '上一步',
  'google.bindgoogle.yvvt3q': '將密鑰備份後保存在安全位置',
  'google.bindgoogle.l8ru6j':
    '若您丟失了手機，此密鑰可用來恢復您的身份驗證器。',
  'google.bindgoogle.7vi621': '否則，客服幫您重置幣趣/谷歌驗證器至少需要7天。',
  'google.bindgoogle.p80e6s': '綁定谷歌驗證器前需要驗證您的帳戶',
  'google.bindgoogle.255n21': '請輸入谷歌驗證器的6位驗證碼',
  'google.bindgoogle.l4i130': '身份驗證器已更改',
  'google.bindgoogle.32j9s6': '您現在可以使用新的身份驗證器登錄。',
  'google.bindgoogle.k8240u': '幣趣/谷歌驗證碼',
  'google.bindgoogle.5wml8v': '下載App',
  'google.bindgoogle.35sm5m': '掃描新二維碼',
  'google.bindgoogle.4z51qi': '備份新密鑰',
  'google.bindgoogle.55623i': '啟用新身份驗證器',
  'google.bindgoogle.5nl6v0': '驗證碼必須為6位數字',
  'google.changegoogle.bp2ib0': '更改谷歌身份驗證器',
  'google.changegoogle.0150qu': '解綁舊身份驗證器前需要驗證您的帳戶',
  'google.changegoogle.892net': '解綁舊身份驗證器',
  'google.unbindgoogle.067sm6': '解綁舊谷歌驗證器',
  'phone.bindphone.6y56ed': '綁定手機驗證',
  'phone.bindphone.14257g': '請輸入短信驗證碼',
  'phone.bindphone.rgfl35': '手機號',
  'phone.bindphone.cjdlml': '請輸入手機號',
  'phone.bindphone.xel50u': '手機驗證碼',
  'phone.bindphone.3c27v0': '手機號不能為空!',
  'phone.changephone.12r3e5': '更改手機驗證',
  'phone.changephone.e5x5v7':
    '為了保護您的資產安全，更改或解綁驗證後該帳戶將禁用支付服務、提幣和C2C賣幣24 小時。',
  'phone.changephone.7wjyni': '請輸入發送到',
  'phone.changephone.780e3h': '的6位驗證碼',
  'phone.changephone.k33121': '更改手機驗證碼',
  'phone.changephone.r34o47': '新手機號',
  'phone.changephone.4end8x': '手機號不可為空！',
  'phone.unbindphone.4yg1w8': '解綁手機驗證',
  'reset.index.6089f8': '重置安全驗證',
  'reset.index.li0671': '請選取不可用的安全項：',
  'reset.index.qihc2i': '確定重置',
  'reset.index.j3x4d5': '的6位短信驗證碼',
  'reset.index.wu2244': '您正在申請以下重置操作：',
  'reset.index.albrnw': '新手機號驗證',
  'reset.index.315820': '新手機驗證碼',
  'reset.index.vs4ka9': '請輸入您要啟用驗證的新郵箱',
  'reset.index.92fyx1': '重置手機號',
  'reset.index.3qyt3q': '重置郵箱',
  'reset.index.307sy0': '關閉谷歌驗證器',
  'reset.index.3gs1p2': '谷歌驗證器不可用，申請重置。',
  'reset.index.6odl42': '請至少選擇一種不可用方式!',
  'reset.index.41hjjt': '手機號{0}不可用，申請重置。',
  'reset.index.jsuwbx': '郵箱{0}不可用，申請重置。',
  'accountsecurity.index.jeh767': '雙重驗證(2FA)',
  'accountsecurity.index.6i7fi7': '遇到問題？',
  'accountsecurity.index.3vr5uk': '綁定',
  'accountsecurity.index.yh5rux': '更改',
  'accountsecurity.index.g4x63c': '解綁',
  'accountsecurity.index.qlf4hm': '高級安全',
  'accountsecurity.index.1h64q4': '開啟',
  'accountsecurity.index.l73sj8': '關閉',
  'accountsecurity.index.i33811': '最近登錄',
  'accountsecurity.index.mc345k': '快捷提幣',
  'accountsecurity.index.wi2qnw': '24小時免驗證額度：',
  'accountsecurity.index.9m6b36':
    '為了您的資金安全，即使您啟用了快捷提幣，我們仍可能會根據您的帳戶安全性要求您進行安全認證。',
  'accountsecurity.index.j7591y': '新增白名單地址禁止提現時間限制',
  'accountsecurity.index.4nrynd':
    '如果關閉此功能，您的提現功能將會被禁止24小時',
  'accountsecurity.index.j7bqkc':
    '關閉此功能後，新添加的白名單地址可立即進行提現地址。',
  'accountsecurity.index.797yxm': '請考慮您的帳戶安全等級後關閉此功能。',
  'accountsecurity.index.688s59':
    '提幣等相關操作需要開啟兩項安全驗證，僅保留一項將影響提幣！',
  'accountsecurity.index.98r7qd': '未綁定',
  'accountsecurity.index.to6o94': '谷歌驗證器(推薦)',
  'accountsecurity.index.zm5d58': '手機驗證',
  'accountsecurity.index.1fmg25': '郵箱驗證',
  'accountsecurity.index.2qgw1x': '提現白名單',
  'accountsecurity.index.r8g22r': '白名單地址安全限制',
  'accountsecurity.index.mxdyyw': '登錄密碼',
  'accountsecurity.index.b47e47': '管理',
  'accountsecurity.index.s7684g': '單筆免驗證額度',
  'accountsecurity.index.2w8i2e': '24小時',
  'accountsecurity.index.705k7v': '48小時',
  'accountsecurity.index.4ee8so': '72小時',
  'accountsecurity.index.528m37': '身份認證',
  'accountsecurity.index.yx274i': '保護您的交易和帳戶安全',
  'accountsecurity.index.21goo8': '啟用',
  'accountsecurity.index.2t85w6': '用於管理您的帳戶登錄密碼',
  'accountsecurity.index.z43335': '提幣設置',
  'accountsecurity.index.7bfk11':
    '開啟本功能後，當您向白名單地址進行小額提幣時可以免除安全驗證',
  'accountsecurity.index.530ut2':
    '一旦啟用此功能，您的帳戶將只能提現到您的白名單地址',
  'accountsecurity.index.i27mc3':
    '通過設置防釣魚碼，您能夠辨別您收到的郵件是否來自於幣趣',
  'accountsecurity.index.q786rj': '設備已被授權允許登錄的帳戶',
  'accountsecurity.index.6968fe': '快捷提幣已開啟!',
  'accountsecurity.index.13dw21': '白名單開啟成功！',
  'accountsecurity.index.g2c5k3': '白名單已關閉！',
  'accountsecurity.index.4p2g2d': '您確定要更改',
  'accountsecurity.index.ks8y15': '您確定要解綁',
  'accountsecurity.index.s123zs': '谷歌驗證器嗎？',
  'accountsecurity.index.747q6p': '手機驗證嗎？',
  'accountsecurity.index.bu8j46': '郵箱驗證嗎？',
  'accountsecurity.index.zxk4z3':
    '為了保護您的資產安全，更改或解綁驗證後該帳戶將禁用支付服務、提幣和 C2C 賣幣 24 小時。',
  'accountsecurity.index.k9sv5l': '開啟提現白名單',
  'accountsecurity.index.5v4733':
    '一旦啟用此功能，您的帳戶將只能提現到您的白名單地址。',
  'accountsecurity.index.4n64be': '請確認您是否要“關閉白名單地址安全限制”？',
  'accountsecurity.index.b1sj33':
    '被解綁或被更改的郵箱在30天內不允許註冊，並且在30天內不允許綁定或更改到其他帳戶。',
  'accountsecurity.index.2399b8':
    '您將能夠提現到任何提款地址，這可能會導致更大的風險。您確定要禁用您的提款白名單嗎？',
  'accountsecurity.index.6c8350': '確認要刪除該設備嗎?',
  'accountsecurity.index.44c15i':
    '禁用後，請在確保帳戶安全的情況下再解禁您的帳戶.',
  'accountsecurity.index.x8b688': '關閉快捷提幣',
  'accountsecurity.index.yoo2x8': '關閉提現白名單',
  'accountsecurity.index.77we05': '請確認您是否要“開啟白名單地址安全限制”？',
  'accountsecurity.index.5c1bpu': '禁止提現時間更改',
  'accountsecurity.index.p95b65':
    '根據您配置的時長，對於新添加的白名單地址會被禁止提現相應的時間',
  'accountsecurity.index.r5318m': '解綁手機前請先綁定郵箱',
  'accountsecurity.index.y27sd3': '解綁郵箱前請先綁定手機',
  'accountsecurity.index.8t87mw':
    '已開啟“白名單地址安全限制”，對於新添加的白名單地址會被禁止提現{0}小時。',
  'identityauthentication.enterpriseerr.f1lrt4': '身份驗證失敗',
  'identityauthentication.enterpriseerr.z33fdl':
    '請重新發起認證流程，並提前核對您的證件是否真實有效，且清晰可見。',
  'identityauthentication.enterpriseerr.71f92d': '重新認證',
  'identityauthentication.enterpriseidcard.h5u4s1': '企業身份認證',
  'identityauthentication.enterpriseidcard.cu8i9m': '企業基本信息',
  'identityauthentication.enterpriseidcard.qvft88': '居住地區',
  'identityauthentication.enterpriseidcard.ln74wk':
    '選擇您現在所居住的國家或地區。您只需要回答當地法律要求回應的問題。',
  'identityauthentication.enterpriseidcard.0hze6m': '國籍',
  'identityauthentication.enterpriseidcard.816vg3': '姓名',
  'identityauthentication.enterpriseidcard.mwg4s7': '選擇證件',
  'identityauthentication.enterpriseidcard.n927hl': '證件號',
  'identityauthentication.enterpriseidcard.6u8b6m':
    '為什麼我需要驗證我的身份？',
  'identityauthentication.enterpriseidcard.dtocnj':
    '驗證身份可讓您獲得更高的提幣及交易限額。24小時內的最高提款限額為50K USDT。',
  'identityauthentication.enterpriseidcard.4kk33v': '中國',
  'identityauthentication.enterpriseidcard.d81gjt': '請輸入姓名',
  'identityauthentication.enterpriseidcard.840u6c': '身份證',
  'identityauthentication.enterpriseidcard.9vrkn4': '請輸入證件號',
  'identityauthentication.enterpriseidcard.q64mg8': '美國',
  'identityauthentication.enterprisesuccess.j1u6ev': '企業身份驗證已完成',
  'identityauthentication.enterprisesuccess.8coosb':
    '您已通過企業KYC認證，可享有無限加密貨幣交易額度',
  'identityauthentication.enterprisesuccess.8qnd58': '開始交易',
  'identityauthentication.enterpriseverification.ns7yt4': '文件驗證',
  'identityauthentication.enterpriseverification.88kdei': '上傳營業執照',
  'identityauthentication.enterpriseverification.ey7dp3':
    '請上傳小於10MB的.jpg/.jpeg/.png格式文件',
  'identityauthentication.enterpriseverification.s64287': '使用攝像頭',
  'identityauthentication.enterpriseverification.hpuo9s': '上傳法人身份證',
  'identityauthentication.enterpriseverification.e3mkb4': '身份證正面(人像面)',
  'identityauthentication.enterpriseverification.cd94nk': '身份證背面(國徽面)',
  'identityauthentication.enterpriseverification.0hxcb7':
    '確保證件符合以下所有標準：',
  'identityauthentication.enterpriseverification.587dk6': '可讀取且清晰明亮',
  'identityauthentication.enterpriseverification.5yiwid': '不反光，不模糊',
  'identityauthentication.enterpriseverification.3qir5b':
    '無黑白圖像，未被編輯',
  'identityauthentication.enterpriseverification.f5d5nf':
    '請將證件至於純色背景下',
  'identityauthentication.idcard.rb3w44': '個人身份驗證',
  'identityauthentication.idcard.0qc2n1': '國家/地區',
  'identityauthentication.idcard.83ui8q': '請前往幣趣手機APP進行身份認證',
  'identityauthentication.idcard.7nqj15':
    '您最多可以交易加密貨幣50K USDT.如需更多限額，請進行高級視頻驗證',
  'identityauthentication.idcard.7nqj14':
    '您最多可以交易加密貨幣50K USDT.如需更多限額，請',
  'identityauthentication.idcard.51831f': '前往幣趣APP進行高級視頻驗證',
  'identityauthentication.idcard.n8e533': '下載幣趣APP',
  'identityauthentication.idcard.xjw8i8': '請選擇或搜索國家/地區',
  'identityauthentication.idcard.0gcncv': '請選擇證件類型',
  'identityauthentication.idcard.d6pvi4': '請填寫所有必填項',
  'identityauthentication.idsuccess.5o2663': '個人信息驗證已完成',
  'identityauthentication.index.c3zv6w': '身份認證後，即刻開啟交易！',
  'identityauthentication.index.t582wo': '個人身份認證',
  'identityauthentication.index.1hjssv': '以個人身份進行交易，提供個人信息。',
  'identityauthentication.index.y28m4x': '我可以同時創建個人賬戶和企業賬戶嗎？',
  'identityauthentication.index.6752e4':
    '抱歉，請您慎重選擇您的賬戶類型，暫不支持多種賬戶類型',
  'identityauthentication.personal.ve2gkh':
    '推薦升級高級認證，可提升交易限額。',
  'identityauthentication.personal.ht7x5k':
    '您已通過高級認證，可享有全部服務。',
  'identityauthentication.personal.b1468c': '初級認證-個人信息',
  'identityauthentication.personal.c64mk9': '普通提幣額度，24小時限額 50K USDT',
  'identityauthentication.personal.2d1typ': 'C2C交易限額 50K USDT',
  'identityauthentication.personal.1b931w': '立即驗證',
  'identityauthentication.personal.877chb': '已驗證',
  'identityauthentication.personal.c3i45p': '高級認證-視頻認證',
  'identityauthentication.personal.cl5cd4': '增加提幣額度，24小時限額 8M USDT',
  'identityauthentication.personal.23n4n6': '增加C2C交易限額 8M USDT',
  'identityauthentication.personal.bc8i1z': '為什麼我必須進行身份認證？',
  'identityauthentication.personal.t13x7g':
    '實名認證是為了遵守當地的金融法規或相關政策。實名認證更有助於保護您的帳戶安全，避免部分欺詐手段；',
  'identityauthentication.personal.e4025p': '認證是否安全？',
  'identityauthentication.personal.642u24':
    '視頻認證僅通過接口進行，幣趣不會保留您的任何個人身份信息，另外，我們也將會對您的信息進行嚴格保密。',
  'identityauthentication.personal.63dlpu': '暫時不支持哪些國家和地區？',
  'identityauthentication.personal.067qd0':
    '由於合規和監管要求，暫時不支持哪些國家和地區——古巴、伊朗、朝鮮、克里米亞、馬來西亞、敘利亞、美國[包括所有美國領土像波多黎各、美屬薩摩亞、關島、北馬里亞納島,和美國維爾京群島(聖約翰的聖克魯斯島和聖托馬斯)]、孟加拉國、玻利維亞、馬耳他、加拿大魁北克省、加拿大安大略省、新加坡等。',
  'identityauthentication.personal.1xnj58': '請前往幣趣手機APP進行視頻認證',
  'identityauthentication.remake.83au88': '經過識別，您的照片:',
  'identityauthentication.remake.ky8q8l': '可讀，清晰不模糊',
  'identityauthentication.remake.i1ev9d': '光線充足，不暗不反光',
  'identityauthentication.remake.mp31d8': '請勿使用黑白圖像',
  'identityauthentication.remake.ui8830': '請同時確保：',
  'identityauthentication.remake.2v4801': '證件未經編輯',
  'identityauthentication.remake.lto47s': '請將證件置於純色背景下',
  'identityauthentication.remake.721tfj': '重拍',
  'identityauthentication.uploaded.zh4393': '證件照已上傳',
  'identityauthentication.verification.60c2cl': '上傳個人身份證',
  'alipay.index.pu3gld': '添加收款方式',
  'alipay.index.r7zk37': '支付寶',
  'alipay.index.wp509f': '上傳',
  'alipay.index.184371': '(支持JPG/JPEG/PNG/BMP格式, 小於1MB)',
  'alipay.index.34512m': '溫馨提示',
  'alipay.index.83il88':
    '當您出售數字貨幣時,您選擇的收款方式將向買方展示。請確認信息填寫準確無誤。請使用與您本人幣趣平台KYC身份認證一致的收款帳戶。',
  'alipay.index.k4b512': '確認',
  'alipay.index.ar7ke7': '支付寶帳號',
  'alipay.index.5gw17n': '支付寶帳號不能為空',
  'alipay.index.d434d5': '請輸入您的支付寶帳號',
  'alipay.index.i6773c': '收款二維碼(選填)',
  'alipay.index.31jn63': '請填寫必填項',
  'alipay.index.68ff1b': '文件大小超出限制，請修改後重新上傳',
  'alipay.index.g0167s': '上傳成功',
  'alipay.index.i1b1c3': '上傳失敗',
  'bankcard.index.122668': '銀行卡',
  'bankcard.index.j3lm0r': '特別提示！',
  'bankcard.index.36w4tq':
    '請確保添加您的銀行卡號以進行即時付款/收款。請勿包含其他銀行或付款方式的詳細信息。您必須添加所選銀行的付款/收款信息。',
  'bankcard.index.v3v417': '銀行帳號/卡號',
  'bankcard.index.oh2r4b': '銀行帳號/卡號不能為空',
  'bankcard.index.55v5l4': '請輸入銀行帳號/卡號',
  'bankcard.index.kjx038': '銀行名稱',
  'bankcard.index.6tj74o': '銀行名稱不能為空',
  'bankcard.index.439w4o': '請輸入您的銀行名稱',
  'bankcard.index.7863u2': '開戶支行(選填)',
  'bankcard.index.su0qyl': '請輸入您的開戶支行',
  'wechat.index.4q6p3g': '微信',
  'wechat.index.1jw088':
    '當您出售數字貨幣時，您選擇的收款方式將向買方展示。請確認信息填寫準確無誤。請使用與您本人幣趣平台KYC身份認證一致的收款帳戶。',
  'wechat.index.323592': '微信帳號',
  'wechat.index.l3prca': '請輸入您的微信號',
  'paymentmethod.index.m2t022': '收款方式',
  'paymentmethod.index.1a7s4d':
    '收款方式：您添加的C2C收款方式將在C2C交易出售數字貨幣時向買方展示作為您的收款方式，請務必使用您本人的實名帳戶確保買方可以順利給您轉賬。',
  'paymentmethod.index.0opj97':
    '管理buy crypto頁面“your credit and debit card”的支付方式',
  'paymentmethod.index.y9sc13': '銀行卡轉賬',
  'paymentmethod.index.xqm1v7': '認證姓名',
  'paymentmethod.index.jdpo9n': '卡號',
  'paymentmethod.index.26qcyf': '開戶行',
  'paymentmethod.index.v1al7g': '編輯',
  'paymentmethod.index.6h4u03': '支付寶轉賬',
  'paymentmethod.index.33vdu3': '帳號',
  'paymentmethod.index.nb557l': '二維碼',
  'paymentmethod.index.2v2sr4': '無',
  'paymentmethod.index.64j352': '微信轉賬',
  'paymentmethod.index.ipl281': '您還沒有任何收款方式',
  'paymentmethod.index.8o4t13': '暫無收款方式',
  'paymentmethod.index.4o3t7g': '確認刪除此收款方式嗎？',
  'paymentmethod.index.4yuz54': 'C2C收款方式',
  'paymentmethod.index.kr8k8g': '快捷買幣',
  'paymentmethod.index.g7423o': '請先完成身份認證',
  'paymentmethod.index.8r14e6': '當前收款方式有進行中的訂單，不可編輯',
  'paymentmethod.index.r0jme1': '當前收款方式有進行中的訂單，不可刪除',
  'setupp.index.eg8e11': '設置',
  'setupp.index.452t1d': '我的個人資料',
  'setupp.index.qd87c0': '頭像',
  'setupp.index.233vb3': '請選擇一個頭像以個性化您的帳戶',
  'setupp.index.vj214g': '活動通知',
  'setupp.index.8p3tf4': '系統消息',
  'setupp.index.51f41n': '幣趣資訊',
  'setupp.index.5cgiog': '交易通知',
  'setupp.index.82461m': '全選',
  'setupp.index.7xr0nm': '通知語言',
  'setupp.index.w81d03': '請選擇語言',
  'setupp.index.m79c1f': '選擇頭像',
  'setupp.index.353554': '選擇一個頭像用作您的個人資料照片',
  'setupp.index.y74j4e': '選擇用於電子郵件和應用推送通知的語言',
  'setupp.index.xdhjw5': '站內信',
  'setupp.index.ps0nd8': '一旦啟用，您將在app和網站內收到相關通知。',
  'forget.step2.2tb10c': '發送驗證碼',
  'forget.step2.t728co': '後發送重新',
  'forget.step2.xktgq7': '生成的6位驗證碼',
  'forget.step2.23m35e': '切換至其他驗證方式',
  'forget.step2.127tlh': '選擇一種驗證器',
  'forget.step3.234452':
    '*為了您的資產安全，更改或解綁驗證後該帳戶將禁用支付服務、提幣和C2C賣幣24小時。',
  'balance.flash.avg5ow': '幣趣閃兌',
  'balance.flash.16e5dd': '兌換記錄',
  'balance.flash.7yxk65': '余額：',
  'balance.flash.5pdf6a': '當前參考價格:',
  'balance.flash.y91k7m': '價格在後',
  'balance.flash.r56244': '更新',
  'balance.flash.979l87': '刷新',
  'balance.flash.p7ss2f': '輸入金額',
  'balance.flash.ks5utw': '立即兌換',
  'balance.flash.vw5c2g': '常見問題',
  'balance.flash.7hx1b2': '是否有手續費？',
  'balance.flash.5bi77n':
    '幣趣閃兌不僅免手續費，還提供高於市場價格的兌換率，讓用戶最大程度地享受福利。',
  'balance.flash.i5c7dy': '支持哪些幣種？',
  'balance.flash.dtr85o': '您正在進行閃兌，參考價格',
  'balance.flash.d8616e': '，當前價格有效期還剩',
  'balance.flash.2mo1kq': '，是否確認？',
  'balance.flash.o1osbj': '當前價格已失效，請重新發起閃兌。',
  'balance.index.6ixcdv': '餘額',
  'balance.index.65v969': '代表總餘額的USDT包括加密貨幣和法幣餘額',
  'balance.index.y78v22': '預估餘額',
  'balance.index.5xh61i': '閃兌',
  'balance.index.k2bw93': '加密貨幣餘額',
  'balance.index.6855lf': '總資產金額',
  'balance.index.m1300v': '可用金額',
  'balance.success.pr8hn6': '兌換成功',
  'balance.success.586403': '支付金額',
  'balance.success.2x4hq2': '兌換金額',
  'balance.success.h3u572': '參考單價',
  'collection.index.mrieh4': '使用幣趣錢包收款',
  'collection.index.4o71tn': '通過多種收款方式接收加密貨幣付款',
  'collection.index.2we6y1': '選擇收款方式',
  'collection.index.4yb2x1': '二維碼或用戶ID',
  'collection.index.n0ph26': '轉賬0手續費',
  'collection.index.9783d8': '無論收款或付款均無任何費用',
  'collection.index.24ge97': '與發送方共享',
  'collection.index.soy1v7': '分享生成的收款二維碼',
  'collection.index.wbmgaq': '查看交易狀態',
  'collection.index.e36sj0': '您和發送方都可以在交易歷史記錄中查看這筆交易',
  'collection.index.865l53': '立即接收',
  'collection.index.b3s2h6': '掃描並轉賬',
  'collection.index.6jm0yl': '我的幣趣ID',
  'collection.index.38jmo5': '用戶名',
  'components.faq.2w458d': '視頻教程',
  'components.faq.j4f5i0': '如何向我的幣趣賬戶儲值數字貨幣',
  'components.faq.vcw5n4': '為什麼我充的幣一直不到賬？',
  'components.faq.25etk3': '填錯、漏填Memo/tag的時候，如何找回我的資產？',
  'components.faq.356p23': '如何購買數字貨幣？',
  'components.faq.jy036g': '查看全部幣種充提狀態',
  'components.figures.6q1l29': '複製充幣地址',
  'components.figures.wg89uc':
    '選擇您要儲值的幣種及其區塊網絡並在本頁面複制儲值地址',
  'components.figures.3t4e1h': '發起提幣',
  'components.figures.u0vqw3': '在對方平台發起提幣',
  'components.figures.5o9w2x': '網絡確認',
  'components.figures.4xw78l': '等待區塊網絡確認您的轉賬',
  'components.figures.0r434g': '充幣成功',
  'components.figures.agse9k': '區塊確認後，幣趣將為您上賬',
  'components.selectcoin.8926wx': '請選擇提現幣種',
  'components.selectcoin.16q188': '選擇幣種',
  'components.selectnet.tn1k41': '選擇轉賬網絡',
  'components.selectnet.7f375j':
    '請確保您本次提現的收款平台支持您要提出的幣種和您提幣使用的網絡。如果您還不能確定，建議您先和收幣平台確認後再提幣。',
  'components.selectnet.5jt6om': '預計到賬時間 ≈',
  'components.selectnet.5c6ffp': '分鐘',
  'components.selectnet.0r7e3h': '費用說明',
  'recharge.edit.sbdp33': '進行中',
  'recharge.index.4iivy9': '數字貨幣儲值',
  'recharge.index.08t186':
    '如果您想使用法幣（例如人民幣、歐元等）購買數字貨幣，可以點擊',
  'recharge.index.fx7y4j': '儲值地址',
  'recharge.index.luaa4b': '請點擊按鈕獲取儲值地址。',
  'recharge.index.2h1v0v': '獲取地址',
  'recharge.index.43a846': '使用交易平台APP或錢包APP掃碼',
  'recharge.index.6gs53g': '此地址只接收',
  'recharge.index.4x3psz': '請再次確認您選擇的主網絡是',
  'recharge.index.x58g67': '請不要將NFT發送到該地址',
  'recharge.index.d02594': '預計到賬',
  'recharge.index.26c7b1': '次網絡確認',
  'recharge.index.qdj1i5': '預計解鎖',
  'recharge.index.jz6i9u': '最小充幣數量',
  'recharge.index.0c907p': '合約地址',
  'recharge.index.g77o3j': '此地址只可接收',
  'recharge.index.dfp1wo': '請不要將 NFT 發送到該地址',
  'recharge.index.608blw': '儲值未到賬？',
  'recharge.index.1q3qvs': '查看詳情',
  'recharge.index.18kyp2':
    '如果您在儲值過程中遇到以下問題，您可以通過“儲值未到賬查詢”功能查詢當前儲值狀態或自助申請找回您的資產。',
  'recharge.index.jln747': '儲值後長時間未到賬',
  'recharge.index.l9ps88': '儲值時忘填或錯填Tag/Memo',
  'recharge.index.x2s98t': '儲值了幣趣未上線的幣種',
  'recharge.index.1s1gn0': '立即查詢',
  'recharge.index.73fh6d': '近期充幣記錄',
  'recharge.index.bdc7lj': '儲值未到賬？點這裡',
  'recharge.index.18p77l': '資產',
  'recharge.index.a5711v': '查看全部',
  'recharge.index.776234': '請求中 請稍後',
  'recharge.index.e09065': '請先選擇轉賬網絡',
  'transferaccounts.index.2s3533': '郵箱不能為空',
  'transferaccounts.index.lf002x': '手機號不能為空',
  'transferaccounts.index.6pr76n': '用戶名不能為空',
  'transferaccounts.index.183816': '幣趣ID不能為空',
  'transferaccounts.index.80v1jn': '轉賬金額不能為空',
  'transferaccounts.index.47n170': '請輸入金額,可精確至{0}位小數',
  'transferaccounts.index.99wa8r': '添加備註',
  'transferaccounts.index.q4dl6q': '備註(選填)',
  'transferaccounts.index.22w46q': '添加常用收款人',
  'transferaccounts.index.72b1l1': '設置支付PIN碼',
  'transferaccounts.index.x677ux': '創建支付PIN碼',
  'transferaccounts.index.r3p3hi': 'PIN碼需滿足6位數字組合',
  'transferaccounts.index.x608ud': '單一數字不可重復3次或以上',
  'transferaccounts.index.77hv2u': '請勿使用4位連續數字組合',
  'transferaccounts.index.8pm8yh': '請再次輸入PIN碼',
  'transferaccounts.index.gvyrgf': '重置PIN碼',
  'transferaccounts.index.r63k73': '當前PIN碼',
  'transferaccounts.index.4vn63o': '新支付PIN碼',
  'transferaccounts.index.ak108t': '禁用支付PIN碼',
  'transferaccounts.index.w91yi6': '接收設置',
  'transferaccounts.index.261157': '通過郵箱地址或手機號接收資金',
  'transferaccounts.index.1akdi9':
    '其他用戶可通過收款方的電子郵箱或手機號向收款方的資金錢包轉賬資產',
  'transferaccounts.index.w97lk9': '支付PIN碼',
  'transferaccounts.index.96f22u': '忘記PIN碼',
  'transferaccounts.index.358pq1': '交易歷史記錄',
  'transferaccounts.index.7918ln': '幣趣用戶之間轉賬免手續費,即時到賬。',
  'transferaccounts.index.34p09t': '收款人',
  'transferaccounts.index.jjo13u':
    '通過郵箱、手機號碼、用戶名、幣趣 ID向幣趣用戶轉賬數字貨幣。',
  'transferaccounts.index.b15688': '收款人可以在用戶中心的用戶ID找到其幣趣ID',
  'transferaccounts.index.hwd69o': '繼續',
  'transferaccounts.index.qf3w26': '添加收款人',
  'transferaccounts.index.842g41': '暫無聯繫人',
  'transferaccounts.index.1486f1': '郵箱',
  'transferaccounts.index.85687o': '手機號碼',
  'transferaccounts.index.jb2g31': '轉賬備註(選填)',
  'transferaccounts.index.8r1kbp': '支付方式',
  'transferaccounts.index.4rj478': '錢包賬戶',
  'transferaccounts.index.8kr6wj': '可用余額：',
  'transferaccounts.index.qrk32f': '余額不足',
  'transferaccounts.index.p1czps': '支付幣種',
  'transferaccounts.index.28y74i': '轉賬成功',
  'transferaccounts.index.g5b418': '再次發送',
  'transferaccounts.index.76452i': '請輸入備註',
  'transferaccounts.index.u22dj7': '您確定要刪除此常用聯繫人嗎?',
  'transferaccounts.index.om6z9f': '收款人郵箱',
  'transferaccounts.index.8597h6': '收款人手機號',
  'transferaccounts.index.3j55lk': '收款人用戶名',
  'transferaccounts.index.f23b7k': '收款人幣趣ID',
  'transferaccounts.index.41p237':
    '為了您的交易安全與便捷，請設置一個6位數字的支付PIN碼',
  'transferaccounts.index.elpdch':
    '您將要離開這個頁面，您輸入的付款信息會被刪除，確認離開?',
  'transferaccounts.index.8g712z': '繼續禁用支付PIN碼？',
  'transferaccounts.index.2w5z6g':
    '禁用支付PIN碼後，您將必須使用2FA來驗證每一筆交易。您後續可以在支付-設置中開啟支付PIN碼。',
  'transferaccounts.index.b4f4w2': '常用收款人',
  'transferaccounts.index.m8280y': '輸入轉賬備註(對方可見)',
  'transferaccounts.index.5v553e': '您的轉賬功能已被禁用',
  'withdrawal.index.71560x': '提現費用',
  'withdrawal.index.6s0k2s': '手續費',
  'withdrawal.index.l86n00': '最小提現金額{0}，可精確至{1}位小數',
  'withdrawal.index.3245em': '數字貨幣提現',
  'withdrawal.index.u06exc': '轉賬到',
  'withdrawal.index.21sf6z': '幣趣用戶',
  'withdrawal.index.t8rcx5': '白名單已開啟您的賬號僅能提現到白名單地址。',
  'withdrawal.index.e1j15c': '添加白名單地址',
  'withdrawal.index.45v07r': '這是一個幣趣用戶ID:',
  'withdrawal.index.6i9x3o': ',立即轉賬,零手續費。',
  'withdrawal.index.at7j1o': '最小提幣數量',
  'withdrawal.index.fd2d7c': '提幣手續費',
  'withdrawal.index.8r166r': '24H剩余提現額度',
  'withdrawal.index.73ox38': '當日剩余額度',
  'withdrawal.index.88j284': '賬戶余額',
  'withdrawal.index.7wx846': '到賬數量',
  'withdrawal.index.g5sz4l': '網絡轉賬費用包含在內',
  'withdrawal.index.5uv3o4': '網絡轉賬費用包含在內',
  'withdrawal.index.ic0r63': '從地址簿中選擇',
  'withdrawal.index.746054':
    '請務必在提現時仔細確認備註、地址、幣種，以及公鏈，否則將有可能導致資金損失！',
  'withdrawal.index.q32r62': '添加新提現地址',
  'withdrawal.index.sm4s27':
    '您已開啟白名單功能，當前僅能向標記為白名單的地址提幣',
  'withdrawal.index.wiqrui':
    '快捷提幣已開啟，提幣至白名單地址時可以免除安全驗證。',
  'withdrawal.index.0eil5v': '到賬',
  'withdrawal.index.mx1436':
    '請確保您輸入了正確的提幣地址並且您選擇的轉賬網絡與地址相匹配',
  'withdrawal.index.p1m1k5': '提幣訂單創建後不可取消',
  'withdrawal.index.04k48l': '提現申請審核中',
  'withdrawal.index.d3dv5h':
    '您的提現申請已進入審核中，預計審核時間12小時，請耐心等待。您可在提現記錄頁面查看本次提現的狀態。',
  'withdrawal.index.4nx0jo': '開啟快捷提幣可以快速提幣至白名單地址',
  'withdrawal.index.jd6j7h':
    '開啟快捷提幣後，您可以在提幣至白名單時免除安全驗證。您可以在“賬戶-賬戶安全”頁面開啟此功能',
  'withdrawal.index.q9pca9': '立即開啟',
  'withdrawal.index.6375ey': '請輸入地址',
  'withdrawal.index.k1ar3u': '點擊這裡，進入幣趣用戶提現界面，零手續費',
  'withdrawal.index.t8utw1': '您的提現功能已被禁用',
  'withdrawal.index.297v9q':
    '修改密碼後未超過24h不可提現，請24h後再進行提現操作',
  'withdrawal.index.203a4f': '請輸入提現金額',
  'withdrawal.index.0j7gm8': '提現金額不能大於賬戶余額',
  'withdrawal.index.cs673u': '提現金額不能大於當日剩余額度',
  'withdrawal.index.d8s17u': '請輸入正確的提幣地址',
  'withdrawal.index.i8n26t': '請輸入正確的地址',
  'withdrawal.user.5i08r6': '數字幣地址轉賬',
  'withdrawal.user.81vy10': '幣趣用戶轉賬',
  'components.figureswith.r4794m': '在幣趣平台創建提幣訂單',
  'components.figureswith.795hkl': '獲取提幣地址',
  'components.figureswith.o48601': '將收款人地址粘貼至提幣頁面的地址輸入框',
  'components.figureswith.1v1uox': '等待區塊網絡確認提幣信息',
  'components.figureswith.q3d8b4': '提現成功',
  'components.figureswith.741j33': '你提現的資產成功已發送至收幣地址',
  'components.footer.hkr883': '近期提幣記錄',
  'components.footer.4wp718': '數字貨幣未到賬？',
  'components.footer.hp7v7v': '隱藏錯誤提示',
  'components.footer.71g73b': '交易對象',
  'components.footer.8kz3w7': '交易詳情',
  'components.footer.4bd6qh': '轉賬金額',
  'components.footer.2p29y6': '收款人帳戶',
  'components.footer.7niq62': '審核中',
  'components.noneaddressbook.h57py3':
    '將常用地址保存在地址簿，可以在將來提幣時直接使用。為每個地址添加標籤方便識別。',
  'components.usetype.gap2oj': '請輸入正確的{0}',
  'components.usetype.193qyk': '請輸入金額，可精確至{0}位小數',
  'components.usetype.c12082': '確認轉賬',
  'components.usetype.578640': '發送到',
  'components.usetype.781tn7': '提幣數量',
  'components.usetype.83383l': '出金帳戶',
  'components.usetype.isnuw4': '請確認收款人信息是正確的',
  'components.usetype.6w5d58': '資金將立即轉成並不支持退款',
  'components.usetype.dy26cx': '接收方可以在資金帳戶查看余額',
  'components.usetype.kyxc2u':
    '無法轉賬到此帳戶，請確保這是個有效並完成認證的帳戶，且收款方開啟了支付-設置-允許通過郵箱或手機號向我轉賬',
  'components.usetype.41254s': '請輸入備註(選填)',
  'components.usetype.jo4wvy': '您的幣趣用户之间的转账功能已被禁用',
  'components.usetype.wyeia0': '請輸入轉賬金額',
  'components.usetype.teccw9': '轉賬金額不能大於帳戶余額',
  'wallethistory.index.9f1e8k': '錢包歷史記錄',
  'wallethistory.index.3p8jpj': '方向',
  'wallethistory.index.3i4539': '自定義時間',
  'wallethistory.index.v1f4xc': '申請已提交',
  'wallethistory.index.kxtger': '自定義',
  'wallethistory.index.8a5y51': '檢索的時間範圍不得超過12個月',
  'wallethistory.index.y6y6n4': '請選擇',
  'wallethistory.index.7fu66b': '訂單號',
  'wallethistory.index.1r786y': '廣告ID',
  'wallethistory.index.8838lh': 'C2C訂單號',
  'wallethistory.index.6xpeei': '輸入交易訂單號',
  'wallethistory.index.ediegq': '輸入廣告ID',
  'wallethistory.index.2s65sr': '輸入C2C訂單號',
  'wallethistory.index.b885tc': '請輸入TxID',
  'wallethistory.index.0k1y2f': '凍結余額',
  'wallethistory.index.ind7f2': '商戶帳戶',
  'wallethistory.index.vadizt': '未查詢到該筆訂單',
  'wallethistory.index.4u2nrc': '近7天',
  'wallethistory.index.t3mb83': '近30天',
  'wallethistory.index.sxz353': '近90天',
  'wallethistory.index.bce1l1': '交易訂單號',
  'wallethistory.index.2idphi': 'C2C訂單',
  'wallethistory.index.ck8goy': '記賬金額類型',
  'wallethistory.index.32d322': '加盟幣趣支付代理,請聯絡Telegram',
  'navbar.index.p8cqf3': '一鍵買幣',
  'navbar.index.075ejk': '儲值',
  'navbar.index.u0jp51': '錢包',
  'navbar.index.n1qu62': '買幣',
  'navbar.index.r0zkso': '轉賬',
  'navbar.index.5ypwz7': '提現',
  'navbar.index.5jxg12': '錢包歷史記錄',
  'navbar.index.6fwd19': '個人用戶',
  'navbar.index.xr75kp': '總覽',
  'navbar.index.fsd231': '推薦返傭',
  'navbar.index.n35329': '賬戶安全',
  'navbar.index.4sr56d': '身份認證',
  'navbar.index.l461w3': '收款方式',
  'navbar.index.dtm61i': '資金管理',
  'navbar.index.dfp47y': '訂單查詢',
  'navbar.index.xg8agy': '設置',
  'navbar.index.7r8gej': '退出登錄',
  'navbar.index.f6enm3': '下載',
  'navbar.index.jft093': '掃描下載App',
  'navbar.index.e4hpuj': '更多下載選擇',
  'components.revence-data.1o1v3x': '收支詳情',
  'components.revence-data.xutv2h': '更多',
  'components.revence-data.96f84q': '狀態',
  'components.revence-data.yy5177': '時間',
  'components.revence-data.2nd8n4': '幣種',
  'components.revence-data.wx899o': '儲值數量',
  'components.revence-data.q052s1': '轉賬網絡',
  'components.revence-data.7c5t77': '地址',
  'components.revence-data.228zrf': '金額',
  'components.revence-data.e6yxx8': '至',
  'components.revence-data.5e8xk1': '備註',
  'components.revence-data.mh514x': '創建時間',
  'components.revence-data.8j5533': '訂單編號',
  'components.revence-data.982243': '提幣申請已提交',
  'components.revence-data.yn42bn': '系統處理中',
  'components.revence-data.wrk1uq': '已完成',
  'components.revence-data.u5bwcq': '提現金額',
  'components.revence-data.141gb1': '實到金額',
  'components.revence-data.lu095t': '手續費',
  'components.revence-data.q86g34': '進行中',
  'components.revence-data.qb2p5y': '成功',
  'components.revence-data.252etx': '失敗',
  'components.revence-data.vfx53c': '審核中',
  'components.revence-data.k4c8ps': '暫無數據',
  'components.revence-data.k715gf': '儲值詳情',
  'components.revence-data.5pbm81': '提現詳情',
  'components.revence-data.wjfj5b': '轉賬詳情',
  'components.revence-data.5fo35v': '類型',
  'components.revence-data.76qs5l': '數量',
  'components.footer.v77lp7': '近期提幣記錄',
  'components.footer.7lynr7': '數字貨幣未到賬？',
  'components.footer.3y1w82': '隱藏錯誤提示',
  'components.footer.imrq6j': '資產',
  'components.footer.ak8p8q': '查看全部',
  'components.footer.3s16r7': '交易對象',
  'components.footer.73i15s':
    '請注意，我們會在提幣完成時給你發送郵件通知，為什麼我的提現',
  'components.footer.s1m739': '還沒到賬？',
  'components.footer.92i2kf': '無',
  'components.footer.196oh4': '交易詳情',
  'components.footer.p0bqf6': '轉賬金額',
  'components.footer.58menr': '收款人賬戶',
  'components.footer.8579va': '收入',
  'components.footer.tgfys9': '支出',
  'components.footer.4mnajt': '完成',
  'components.footer.18b1ms': '數字幣地址轉賬',
  'components.footer.531kp1': '幣趣用戶轉賬',
  'wallethistory.index.5i78a8': '方向',
  'wallethistory.index.8w6804': '自定義時間',
  'wallethistory.index.47v114': '全部',
  'wallethistory.index.5ghw1q': '從',
  'wallethistory.index.2sphc2': '自定義',
  'wallethistory.index.529g8d': '檢索的時間範圍不得超過12個月',
  'wallethistory.index.h55bzf': '確認',
  'wallethistory.index.2ovnj2': '請選擇',
  'wallethistory.index.hk6yeh': '訂單號',
  'wallethistory.index.n3h46i': '廣告ID',
  'wallethistory.index.z1r79x': 'C2C訂單號',
  'wallethistory.index.uq1xwt': '輸入交易訂單號',
  'wallethistory.index.7g829o': '輸入廣告ID',
  'wallethistory.index.qqo536': '輸入C2C訂單號',
  'wallethistory.index.lergm7': '請輸入TxID',
  'wallethistory.index.o22no8': '凍結余額',
  'wallethistory.index.4nyb38': '可用余額',
  'wallethistory.index.tj74nd': '未查詢到該筆訂單',
  'wallethistory.index.f898io': '近7天',
  'wallethistory.index.v4u435': '近30天',
  'wallethistory.index.8l028p': '近90天',
  'wallethistory.index.t7ec78': '記賬金額類型',
  'wallethistory.index.mqe2y8': '交易訂單號',
  'wallethistory.index.l4dp73': 'C2C訂單',
  'wallethistory.index.l4d233': '暫無數據',
  'wallethistory.index.l423d3':
    '為了保護您的資產安全，更改或解綁驗證後該帳戶將禁用支付服務、提幣和C2C賣幣24小時。',
  'safetydialog.index.178bx6': '支付PIN碼驗證',
  'safetydialog.index.557581': '請輸入支付PIN碼',
  'safetydialog.index.2e2215': '切換至其他驗證方式',
  'safetydialog.index.zj74uq': '發送驗證碼',
  'safetydialog.index.193173': '後發送重新',
  'safetydialog.index.284434': '請輸入',
  'safetydialog.index.uo4t31': '生成的6位驗證碼',
  'safetydialog.index.4yhgsc': '的6位驗證碼',
  'safetydialog.index.kh189w': '切換至PIN碼',
  'safetydialog.index.pnpoko': '選擇一種驗證器',
  'safetydialog.index.wks78g': '驗證碼必須為6位數字',
  'safetydialog.verification.j6a316': '安全驗證不可用？',
  'login.verification.3yt211': '登錄成功',
  'login.verification.h937w2': '請稍後再試',
  'texttool.index.8tkh16': '什麼是防釣魚碼？',
  'texttool.index.8322uj':
    '防釣魚碼是你自己設定的一串字符，能夠幫助你識別防冒幣趣的網站或者郵件',
  'texttool.index.d4btk5': '防釣魚碼會出現在哪？',
  'texttool.index.85q6ja':
    '設定好防釣魚碼後，每一封幣趣發給您的郵件都會帶有這串字符。',
  'texttool.index.614p6n': '為了帳戶安全，幣趣建議您經常更換釣魚碼',
  'message.index.nu55o6': '消息中心',
  'message.index.040p38': '隱藏已讀通知',
  'message.index.45l897': '取消刪除',
  'message.index.j53q1c': '逐個刪除',
  'message.index.cr0g63': '清空全部',
  'message.index.3s11dy': '暫無通知',
  'message.index.5149es': '全部已讀',
  'message.index.v8v63c': '確定清空所有消息嗎？',
  'message.index.rs86ai': '確定刪除這條消息嗎？',
  'message.index.813ex2': '確定已讀所有消息嗎？',
  'message.index.6id33f': '全部通知',
  'message.index.3mx6r2': '系統通知',
  'withdrawal.index.sl37o9': '最小提現金額',
  'disableacc.index.4he22l': '刪除帳戶',
  'disableacc.index.4600vj': '刪除您的帳戶將導致以下後果: ',
  'disableacc.index.1n1xyl': '您的帳戶資訊與所有記錄都將被清除，請問是否繼續？',
  'activity.index.3v228s': '邀請好友 一起賺幣',
  'activity.index.edb45q':
    '邀請方和受邀用戶在 C2C 交易、首次儲值、支付中一起賺幣。',
  'activity.index.yu66tq': '查看推薦規則',
  'activity.index.w3to2t': '邀請碼',
  'activity.index.r5c8le': '複製',
  'activity.index.07k677': '推薦連結',
  'activity.index.7c2lsz': '邀請好友',
  'activity.index.0f7d7m': '受邀用戶',
  'activity.index.tgo3f2': '推薦人返佣',
  'activity.index.k3pq4e': '受邀者返佣',
  'activity.index.iq3uti': '*數據統計時間以 UTC+8 時間為準。',
  'activity.index.4xx2p5': '僅顯示過去',
  'activity.index.3d4v15': '天的記錄。如需要查看詳細交易記錄，請點擊“匯出”。',
  'activity.index.al57mt': '匯出',
  'activity.index.g50728': '推薦碼',
  'activity.index.2sxu6b': '立即報名。一起賺幣',
  'activity.index.24767a': '您的收益',
  'activity.index.41k75q': '開始交易的好友',
  'activity.index.1srwpj': '好友數',
  'activity.index.ghhu65': '暫無記錄',
  'activity.index.njgm7p': '是否要進行“匯出”操作？',
  'activity.index.k3v982': '複製連結',
  'activity.index.yh2778': '複製成功',
  'activity.index.t4g7z4': '複製失敗',
  'activity.index.ye8x52': '全部時間',
  'activity.index.e517a8': '昨日',
  'activity.index.k1q8v4': '本週',
  'activity.index.28s1pm': '本月',
  'activity.index.2x16v8': '點擊連結，立即註冊，即刻開始交易。',
  'activity.index.71420h': '一',
  'activity.index.66imlc': '使用者 ID',
  'activity.index.587i85': '邀請時間',
  'activity.index.15vt2o': '返佣級別',
  'activity.index.x27398': '返佣類型',
  'activity.index.f84ctg': '返佣金額',
  'activity.index.7ud5d7': '返佣幣種',
  'tab.yet1-1': '如果您尚未安裝，請先下載。',
  'tab.yet1-2': '打開App',
  'resigter.index.e142w4': '邀請碼（選填）',
  'resigter.index.8o8gw4': '請輸入推薦碼',
  'activity.index.sdj13m': '一級',
  'activity.index.tcy327': '二級',
  'activity.index.eit3w1': '被邀請人',
  'activity.index.31bq7k': '支付',
  'activity.index.0i8xbr': '首充',
  'activity.index.7t1d28': '使用瀏覽器訪問 {0} 註冊 BasicEx，享受多重禮遇！',
  'financial.index.a416j6': '理財',
  'financial.index.u17v8y': '簡單、安全。搜索熱門幣種，立即賺取收益。',
  'financial.index.9wn75k': '預估理財資產',
  'financial.index.0aez31': '30日累計收益',
  'financial.index.7l6xk6': '歷史',
  'financial.index.74sqjr': '申購',
  'financial.index.45m1g2': '活期',
  'financial.index.u45h04': '天',
  'financial.index.1lb333': '收起',
  'financial.index.lvk700': '查看更多',
  'financial.index.4i534j': '搜索幣種',
  'financial.index.ic2158':
    '當前產品為定期理財，提前贖回將喪失所有收益，是否確認贖回？',
  'financial.index.h53vii': '返還本金',
  'financial.index.9ql3t3': '繼續收益',
  'financial.index.7k4y73': '全部周期',
  'financial.index.9o8661': '定期',
  'financial.index.d4y434': '所有產品',
  'financial.index.7fe254': '當前持倉',
  'financial.index.cl022e': '預估年利率',
  'financial.index.rlc92n': '期限',
  'financial.index.ry7o62': '持倉數量',
  'financial.index.7x13ac': '固定年利率',
  'financial.index.j4adht': '累計收益',
  'components.redemptionmodal.f6wol5': '贖回',
  'components.redemptionmodal.8tdo21': '贖回金額',
  'components.redemptionmodal.1dyoq9': '最大',
  'components.redemptionmodal.i1tf10': '可贖回：',
  'components.redemptionmodal.c2f84y': '快速贖回',
  'components.redemptionmodal.313789': '赎回金額立即到賬',
  'components.redemptionmodal.k5z838': '最大可贖回 {0}{1}',
  'components.subscriptionmodal.4z6146': '期限 ( 天 )',
  'components.subscriptionmodal.vu0s2x': '申購金額',
  'components.subscriptionmodal.2l381c': '可用保證金',
  'components.subscriptionmodal.hs6266': '沒有足夠的加密資產？',
  'components.subscriptionmodal.n4iiqu': '數量限制',
  'components.subscriptionmodal.k14498': '最少可投：',
  'components.subscriptionmodal.210l8f': '剩余額度：',
  'components.subscriptionmodal.6o1pe5': '概覽',
  'components.subscriptionmodal.69kj53': '申購日期',
  'components.subscriptionmodal.e2tf8d': '起息日',
  'components.subscriptionmodal.crg825': '首次獎勵發放日',
  'components.subscriptionmodal.d733lj': '固定年化利率，每日計息。',
  'components.subscriptionmodal.15rpj4': '收益不以法幣計算。',
  'components.subscriptionmodal.2750lb': '可隨時提取。',
  'components.subscriptionmodal.782584': '提前贖回將無法產生收益。',
  'components.subscriptionmodal.cn60q7': '我已閱讀並同意',
  'components.subscriptionmodal.7njv30': '幣趣理財服務協議',
  'components.subscriptionmodal.vk7tu8': '當前幣種可用餘額',
  'components.subscriptionmodal.ii6354': '當前可購買的最大額度',
  'components.subscriptionmodal.7585sb': '預估每日利息',
  'components.subscriptionmodal.94bpyq': '預估利息',
  'components.subscriptionmodal.8x2l76': '申購金額不得高於可用保證金',
  'components.subscriptionmodal.7pv85t': '最少可投{0} {1}',
  'components.subscriptionmodal.r8z1in': '剩余额度{0} {1}',
  'components.successmodal.c86btp': '申購成功',
  'components.successmodal.s5mxe3': '贖回已確認',
  'components.successmodal.n55j08': '正在等待處理',
  'components.successmodal.81qw5t': '預計贖回時間',
  'components.successmodal.p1lcda': '*統一生效時間以UTC+8為準',
  'financial.index.51d412': '贖回歷史',
  'components.redemptionmodal.6w3q97': '如未全部贖回，則優先贖回收益。',
  'components.history.722wpc': '理財管理',
  'components.history.wrvmwf': '30日累計收益記錄',
  'components.history.d27w35': '產品',
  'components.history.b37656': '申購時間',
  'components.history.821kxk': '年利率',
  'components.history.oq7jxy': '發放收益',
  'components.history.023us6': '發放時間',
  'components.historyred.73r9w9': '確認中',
  'components.historyred.fxsgdj': '已贖回',
  'components.historyred.l43b8v': '收益',
  'components.historyred.3lfq3n': '贖回時間',
  'components.historyred.246k93': '贖回成功',
  'financial.index.s1t66k': '預估每日收益',
  'financial.index.2g4uc2': '到期收益',
  'components.editmodal.bm5h27': '赎回详情',
  'components.editmodal.d6bc00': '到期時間',
  'wallethistory.index.pe3x7i': '收益中',
  'wallethistory.index.2343kc': '已关闭',
  'components.simple.756g85': '1.綜述',
  'components.simple.08c0iq':
    '1.1 鏈上賺幣服務(「本服務」)是HOLALIMITED及其關聯公司（統稱為「幣趣」）推出的透過參與鏈上數位貨幣或其他數位資產(「此數位資產」)質押並獲取獎勵的產品,並根據某些條款和條件允許贖回。由幣趣負責產品的設計管理及本服務相關營運服務。為使用本服務，您應閱讀並遵守本鏈上賺幣使用者協議(「本協議」)。請您務必審慎閱讀、充分理解並同意各條款內容。',
  'components.simple.u4s2bs':
    '1.2除非您已閱讀並接受本協議所有條款，否則您無權或不應該使用本服務。您接受本協議的行為，以及您使用本服務的行為即視為您已閱讀、瞭解並同意本協議，本協議對您具有法律約束力。',
  'components.simple.7kr04z':
    '1.3本協議應補充幣趣服務條款。如果本協議中規定的任何條款或規定與幣趣服務條款發生任何衝突或不一致，應透過優先考慮本協議來解決此類衝突或不一致。幣趣服務條款中未被本協議修改的所有其他規定仍具有完全之效力。',
  'components.simple.i612nw': '2.定義',
  'components.simple.pd935k':
    '2.1.鏈上賺幣是指在區塊鏈網路中鎖定並持有數位資產以參與其共識鏈上協議並獲得獎勵的做法。鏈上賺幣可能涵蓋各種區塊鏈網路和技術。所涉及的具體功能和技術可能會根據相關的區塊鏈網路而有所不同。鏈上賺幣可以包括不同的區塊鏈網路和技術，它主要涉及參與共識鏈上協議，例如權益證明（「PoS」），以保護網路並驗證交易。',
  'components.simple.ur1w4y':
    '2.2 本服務是實現適用區塊鏈網路的PoS鏈上協議的解決方案，涉及鏈上質押以獲得獎勵。',
  'components.simple.quu3go': '2.3 與服務相關的其他定義包括：',
  'components.simple.6o03hk':
    '本金：為使用本服務而存入用戶帳戶的一定數量之數字資產。',
  'components.simple.114i84':
    '贖回：質押的該數位資產贖回需合理時間/日數提取至帳戶。',
  'components.simple.2588q2': '獎勵計算日: 具有第4.4(a) 節中的意義。',
  'components.simple.131i2v':
    '服務費:指用戶在申購或參與本服務時須向幣趣支付的費用，其中包括但不限於驗證節點佣金費、上下鏈礦工費（gasfee）等。',
  'components.simple.5y670y':
    '質押：本服務中，幣趣將用戶一定數量的數位資產凍結，並接收代幣或其他數位資產作為PoS。在質押後，用戶在贖回期限前將無法贖回或提取資產。',
  'components.simple.7w4en4': 'T日：以UTC+8地域時間計算的自然日。',
  'components.simple.x831kf':
    '可變質押率: 具有第4.4(c)條中的含義以及用戶在認購本服務期間幣趣平台上顯示和引用的含義。',
  'components.simple.e612g4': '3.使用本服務的資格',
  'components.simple.vzb4x4': '3.1 您同意並確認：',
  'components.simple.v6iq7u': 'a.幣趣全部或部分質押您的數位資產；',
  'components.simple.53c448': 'b.幣趣在適用網絡上充當驗證者； 和',
  'components.simple.48k24p':
    'c. 將與您質押的該數位資產相關的任何投票權委託給幣趣；',
  'components.simple.08kj10':
    'd.幣趣可不時就服務指定，認購服務所需的最低質押數位資產數量；任何贖回期；任何贖回限制;任何認購限制.',
  'components.simple.4zwvtu': '3.2 您亦了解並確認：',
  'components.simple.nuu7bv':
    '您已同意遵守幣趣服務條款，同時您有資格且不被禁止使用其中定義的「服務」；',
  'components.simple.21xq44':
    '如果您正在以個人身分註冊使用本服務，為了有效接受本協議，您必須年滿18歲，是具有完全民事權利能力和民事行為能力的個人，具有簽署本協議所有必需的權力和能力，本協議一經簽署對您具有約束力；',
  'components.simple.18143v':
    '如果您是代表法人實體註冊使用本服務，為了有效接受本協議，您聲明並保證（i）此類法律實體根據適用法律合法成立並有效存在;（ii）您獲得該法律實體的正式授權，有權代表其行事。',
  'components.simple.izihsy':
    '您具備使用本服務相應的風險識別能力和風險承受能力，擁有投資非保本類數位資產產品的經驗和相關知識；',
  'components.simple.4a2i9n':
    '為使用本服務，您具備在互聯網上進行相應操作的能力；',
  'components.simple.dl4sk2':
    '本協議與您所在國家或地區的法律沒有衝突，您保證將遵守所在國家或地區的法律；',
  'components.simple.l2eqy3':
    '您是您幣趣平台帳戶中數位資產的合法所有者，並保證這些數位資產的來源是合法的。',
  'components.simple.830685': '3.3 您亦同意並承諾：',
  'components.simple.oowst3':
    '您不會將本服務用於任何非法目的、進行、發起或促進任何形式的市場操縱、價格操縱、內線交易、市場扭曲、任何其他惡意的市場行為或其他形式的非法行為，包括但不限於非法賭博，洗錢，欺詐，勒索，敲詐勒索，資料洩露，恐怖主義融資，其他暴力活動或任何法律禁止的商業行為。',
  'components.simple.26iroe':
    '3.4您了解並接受使用本服務可能對您產生的風險和您可能因此而遭受的相關損失，包括但不限於：',
  'components.simple.gcf336':
    '您所持有的數位資產質押後因您無法對該等被凍結的數位資產進行交易等操作而引起的不可交易風險，您願意承擔數位資產質押帶來的資金風險及您可能遭受的經濟損失；',
  'components.simple.tc17i7':
    '幣趣在頁面展示用戶預期收益為預期理論收益而非幣趣所保證或承諾的實際收益，實際收益可能與預期收益不符合，您的最終收益將以您收到的實際收益為準，幣趣將根據質押相關操作所產生的實際收益減去幣趣代用戶進行質押相關操作的成本、費用和支出（包括但不限於伺服器費用、電費等）減去幣趣收取的質押相關手續費的方式計算用戶的實際收益，幣趣不會對您可能收到的實際收益作出任何保證或承諾；',
  'components.simple.0ph46i':
    '數位資產及其衍生性商品的高價格波動可能會在短時間內造成重大或全部損失;',
  'components.simple.pj8gsy':
    '可能會發生技術異常，這可能會延遲或阻止您使用本服務或進行相關交易等操作，這可能導致您的數位資產損失;',
  'components.simple.c3wp4v': '可能發生市場異常，這可能導致您的數位資產損失；',
  'components.simple.4642wm':
    '為了維持市場的整體健康，幣趣需要不時添加、刪除或更改政策、本協議條款或其他相關服務協議或條款的相關規定。此類行為可能有利於或損害像您一樣的個人客戶。',
  'components.simple.49x4f1': '4.使用本服務',
  'components.simple.19w0n6':
    '4.1您可以透過幣趣客戶端下達質押、贖回等操作指令。一旦操作指令完成後，您將無法取消、撤銷或修改指令。',
  'components.simple.3cix8w':
    '4.2質押成功後您將自動委託並授權幣趣對被鎖帳戶中的一定數量的該數位資產進行包括但不限於投票、數位資產增益行為。',
  'components.simple.fkx5je':
    '4.3一旦該數位資產被質押或鎖定，幣趣將向您發行代幣或其他數位資產作為獎勵。該數位資產的使用應遵守幣趣在幣趣平台不時公佈的規則和條款，幣趣有權隨時自行決定允許、考慮、修改或取消任何出於任何目的對該數位資產在幣趣平台的任何使用。',
  'components.simple.73e58y': '4.4 質押獎勵',
  'components.simple.1l6su3':
    'a.質押獎勵將根據特定鏈上協議的累積時間開始累積，這可能會根據您質押的區塊鏈或網路而有所不同。獎勵的累積將從您認購服務之日（稱為「獎勵計算日」）開始，並按照鏈上協議指定的累積時間表進行。質押獎勵將根據一年365天過去的天數來計算。請注意，您認購的第一天不會獲得任何質押獎勵；您的獎勵將從第二天開始累積，與鏈上協議確定的每日累積時間一致;',
  'components.simple.60u1s3':
    'b.質押獎勵將根據特定鏈上協議的分配時間表分配到您的資金帳戶，該分配時間表可能會根據您質押的區塊鏈或網路而有所不同。這些獎勵將在每個獎勵計算日的次日記入您的資金帳戶，前提是與鏈上協議指定的分配時間一致。請注意，分發的時間和頻率受鏈上協議制定的規則和準則的約束；',
  'components.simple.kjo2z0':
    'c.參考質押獎勵產生當日的可變質押利率計算。為避免疑問，這種&ldquo;可變質押率&rdquo;，即在鏈上收益情況下每天可能變化的APY，應在幣趣平台上公佈，並且可能會不時發生變化；',
  'components.simple.trfh0l': 'd. 計算並四捨五入至小數點後8 位。',
  'components.simple.8q8zh6':
    '為避免疑問，幣趣可能會保留部分鏈上獎勵。該金額可能與質押獎勵不同。幣趣不保證您將按照您認購時顯示的可變質押率獲得質押獎勵。關於您認購時可能回報的任何陳述(i)僅是指示性估計而不是保證，(ii)基於可變質押率，該利率可能會發生變化，並且(iii)可能高於或低於連鎖獎勵。',
  'components.simple.3e5int': '4.5 贖回規則',
  'components.simple.mp83y6':
    'a.贖回的數位資產到達使用者帳戶需遵守鏈上協議的兌換規則，具體時間可能會有所不同。請注意，贖回時間可能會因數位資產類型而異。您瞭解並同意，在贖回時間至實際到帳時間之間，您將無法對所贖回的數位資產進行任何回饋、轉帳或其他指示。',
  'components.simple.8q351n':
    'b. 在贖回日當天或之後，幣趣可能只允許每天贖回有限數量的數位資產。',
  'components.simple.u54482':
    'c.一旦您提交了贖回請求，您將不再獲得與您請求贖回數位資產相關的任何質押獎勵。為避免疑問，將數位資產贖回之行為不符合任何質押獎勵的條件。一旦下達和/或確認贖回訂單，該訂單將不能被取消、撤銷或修改。',
  'components.simple.30rpa8':
    'd. 您應能在提出贖回請求後的合理時間內收到數位資產。',
  'components.simple.7x1wdy':
    'e.所有贖回請求均由幣趣按照先進先出（FIFO）的原則處理，並在當天結束時（UTC+8收市）由幣趣處理。',
  'components.simple.zr617j':
    'f.幣趣保留對處理數位資產贖回收取服務費的權利，該服務費應在幣趣平台上公佈，並可由幣趣不時修改。',
  'components.simple.o87n4p': 'g. 您瞭解並承認，您在贖回期間不會獲得質押獎勵。',
  'components.simple.46u02b':
    '4.6您將能夠在幣趣平台上查看您的帳戶歷史記錄。您必須仔細查看您的帳戶歷史記錄，如果您發現任何您不認識或您認為不正確的條目或交易，請盡快告知幣趣。幣趣可以隨時糾正您帳戶歷史中的任何錯誤，並保留取消或撤銷任何交易的權利：',
  'components.simple.7wu1il':
    'a.涉及或源自明顯錯誤（即任何明顯的錯誤、遺漏或錯誤引用（無論是幣趣或任何第三方的錯誤），包括幣趣任何代表在考慮當前市場和當前廣告報價後的錯誤報價，或任何資訊、來源、官方、官方結果或其他的任何錯誤）；或',
  'components.simple.a45t8y': 'b. 反映我們合理認為是正確或公平的交易細節。',
  'components.simple.6o3ll0':
    '4.7根據適用法律法規，幣趣可能需要與第三方分享有關您的帳戶和使用幣趣平台的資訊。您確認、同意並允許我們有權揭露此類資訊。',
  'components.simple.g9h47v': '4.8 服務費',
  'components.simple.4ky6gb':
    'a.幣趣可就向您提供本服務收取相應的服務費。除非幣趣平台另有說明,幣趣收取的服務費為您在本服務項下的實際收益（包括利息和獎勵）的指定百分比，而幣趣將從您的實際收益中扣除相應的服務費作為服務費的支付方式。',
  'components.simple.bvm49h':
    'b.您同意並授權幣趣，幣趣有權從您帳戶中的資產扣除應付的服務費，否則幣趣有權不向您提供本協議項下服務。',
  'components.simple.ccy320': '5.暫停或取消您的認購',
  'components.simple.db7x1a':
    '5.1.根據幣趣服務條款，幣趣可以停止、暫停或取消您對幣趣平台的訪問，對任何幣趣帳戶施加限制，暫停或取消您認購本服務的能力，或延遲某些交易：',
  'components.simple.v196mj':
    'a. 您沒有依照幣趣的要求提供額外資訊來驗證您的身分或資金來源；',
  'components.simple.18id34':
    'b.幣趣依照法院命令，以遵守任何適用法律法規（包括反洗錢或製裁法）；',
  'components.simple.26trc8': 'c.幣趣遵守政府部門或機構的任何指示或指示；',
  'components.simple.z13es2':
    'd.幣趣合理地確定您向幣趣提供的任何資訊是錯誤的、不真實的、過時的或不完整的；或',
  'components.simple.5h7y44':
    'e. 您沒有依照幣趣的要求而提供合理滿意或及時要求的額外資訊。',
  'components.simple.79g0s2': '責任限制',
  'components.simple.43vw58':
    '6.1您同意並接受：幣趣在頁面上展示用戶預期收益為預期理論收益而非幣趣所保證或承諾的實際收益，實際收益可能與預期收益不符合或低於預期收益，您最終的收益以實際收益為準。幣趣對預期收益或您可能收到的實際收益不做任何承諾或保證。',
  'components.simple.ad2oq2':
    '6.2您同意並接受：在您贖回時，部分幣種的到帳時間可能不同，以幣種頁面顯示為準，幣趣對任何因到帳時間的存在及其差異而導致的損失不承擔任何責任。',
  'components.simple.474yoj':
    '6.3您同意並接受：幣趣有權自行決定隨時修訂本協議的內容，如您對本協議或其任何更改存在誤解或未及時閱讀本協議的修訂，幣趣對您因此而造成的任何損失不承擔任何責任。',
  'components.simple.r52ir9':
    '6.4您同意並接受：當幣趣系統平台因下列狀況無法正常運作或導致服務中斷，使您無法使用各項服務或無法正常下達指令或進行相關交易等操作時，包括但不限於失敗、延遲、中斷、停止、系統無回應、系統反應緩慢或其他無法正常使用本服務的情況，幣趣不承擔損害賠償責任，此狀況包括但不限於：',
  'components.simple.3438jn': '幣趣平台公告之系統停機維護期間；',
  'components.simple.3w0472': '電信設備故障不能進行資料傳輸的；',
  'components.simple.2zleu8':
    '因颱風、地震、海嘯、洪水、瘟疫流行、停電、戰爭、動亂、政府行為、恐怖攻擊等不可抗力或其他意外事件之因素，造成幣趣平台系統障礙不能執行業務的；',
  'components.simple.i2y243':
    '因駭客攻擊、電腦病毒侵入或發作、電信部門技術調整或故障、網站升級、銀行方面的問題、因政府管製而造成的暫時性關閉等影響網路正常經營的原因而造成的服務中斷或延遲；',
  'components.simple.gn8q3r':
    '幣趣系統平台因電腦系統遭到破壞、癱瘓或無法正常使用而造成的服務中斷或延遲；',
  'components.simple.c466p0':
    '因為產業現有技術力量無法預測或無法解決的技術問題所造成的損失；',
  'components.simple.j1764b':
    '因第三方的過失或延誤而對您或其他第三方造成的損失；',
  'components.simple.p66n4w':
    '因法律法規或政府、監管或執法指令的變更而對您或其他第三方造成的損失；',
  'components.simple.q6x7d8':
    '因其他不可預見、無法避免且無法克服的不可抗力或意外事件而對您或其他第三方造成的損失。',
  'components.simple.jk53lr':
    '您同意並接受：上述原因可能導致的異常成交，行情中斷，以及其他可能的異常情況，幣趣有權根據實際情況不執行您的指示。您了解並同意幣趣對上述情形而對您造成的任何損失（包括但不限於任何直接或間接損失、實際損失或可得利益損失等任何損失）不承擔任何責任。',
  'components.simple.18dj4m':
    '6.5您同意並接受：對於您因本協議3.3條所述的風險而遭受的任何損失（包括但不限於任何直接或間接損失、實際損失或可得利益損失等任何損失），幣趣不承擔任何責任。',
  'components.simple.m7quwg': '技術免責聲明',
  'components.simple.o7k2x3':
    '7.1幣趣可能會不時暫停您對幣趣帳戶、幣趣平台、本服務和/或任何其他幣趣服務的訪問，以進行定期和緊急維護。幣趣將盡合理努力確保及時處理幣趣平台上的交易，但幣趣對完成處理所需的時間不作任何陳述或保證，這取決於幣趣無法控制的許多因素。',
  'components.simple.e4dxgz':
    '7.2儘管幣趣已盡合理努力更新幣趣平台上的信息，但幣趣不就幣趣平台上的內容，包括與本服務及任何其他幣趣服務相關的信息作出任何明示或暗示的陳述、保證或擔保,是準確的、完整的或最新的。',
  'components.simple.ebq62o':
    '7.3您應對於取得使用本服務或任何其他幣趣服務所需的資料網路存取權限負責任。您應負責獲取和更新訪問和使用本服務、任何幣趣服務、幣趣平台及其任何更新所需的兼容硬體或設備負責任。幣趣不保證任何幣趣服務或其任何部分將在任何特定硬體或設備上運行。幣趣服務可能會因使用網路和電子通訊而發生故障和延遲。',
  'components.simple.50o29k': '強制措施',
  'components.simple.8vo15i':
    '您同意並接受：幣趣嚴格禁止不公平的交易行為。如果您參與以下行為，幣趣保留自行決定對您的帳戶實施高階主管控制權或關閉您的帳戶的權利：',
  'components.simple.716s6i':
    '參與市場操縱、價格操縱、內幕交易、市場扭曲、任何其他惡意的市場行為或任何其他惡意的市場行為;',
  'components.simple.y7tut2':
    '可能透過利用本服務的漏洞或其他不合理的手段損害其他用戶或幣趣;',
  'components.simple.syju60': '參與幣趣認為對市場有害的任何其他活動；',
  'components.simple.tvfc1s': '參與違反任何法律法規的活動;',
  'components.simple.a24892':
    '為了消除任何對市場整體健康的不利影響，幣趣保留自行決定採取以下措施的權利，包括但不限於關閉您的帳戶，限製或取消指示。您了解並同意幣趣對因採取上述措施而給您帶來的任何損失（包括但不限於任何直接或間接損失、實際損失或可得利益損失等任何損失）不承擔任何責任。',
  'components.simple.reg83l': '賠償責任',
  'components.simple.61co62':
    '9.1您應該採取一切可能的措施保證幣趣免受任何來自於您使用本服務或其他與您的幣趣帳戶相關的行為的損害，否則您應該對幣趣承擔賠償責任。',
  'components.simple.q08qt1':
    '9.2您在此確認並同意，對因您違反本協議的行為、您不正當使用幣趣的服務的行為、您違反任何法律法規或侵犯任何第三方權利的行為、經您授權使用您的幣趣帳戶或登入幣趣網站、客戶端、系統（包括用於提供任何幣趣服務的網路和伺服器，且無論該等網站、客戶端和系統是由幣趣運營，或者是為了幣趣而運營，或者為了為向您提供幣趣服務而運營）的任何第三方的作為或不作為而引起的任何索賠、要求或主張（包括一切合理的法律費用或其他費用或支出），您應該進行抗辯、賠償，並盡一切努力使幣趣免受傷害。',
  'components.simple.vsc184': '其它',
  'components.simple.71o7m1':
    '10.1本協議內容同時包括幣趣的各項制度規範、其它本協議附件中的協議或規則、幣趣可能不斷發布的關於本服務的其他相關協議、規則等內容。上述內容一經正式發布，即為本協議不可分割的組成部分，您同意您將認真閱讀該等協議或規則並應遵守該等協議或規則的相關規定，您同意該等協議或規則對您具有法律約束力。上述內容與本協議有衝突的，以本協議為準。',
  'components.simple.drg5f9':
    '10.2如有必要，幣趣有權自行決定隨時單方變更本協議的內容，且幣趣無需事前通知您。如果幣趣單方變更了本協議的內容，變更的內容將立即在幣趣的網站上公佈，屆時請您及時查看並認真閱讀上述網站上的最新資訊。在上述變更公佈後，您了解並同意如果您繼續使用本服務，則視為您已經同意對本協議的變更並且該等變更對您具有法律約束力。如果您不同意本協議的變更，您應該停止使用本服務。',
  'components.simple.8i03ph':
    '10.3您應該認真閱讀本協議以及本協議第10.1條和10.2條所提到的任何構成本協議的內容的其他相關協議、規則等內容。如果您對上述內容有任何疑問，請透過SupportCenter聯繫我們的客戶服務，幣趣將提供合理且必要的說明。除非幣趣有其他要求，否則您應該透過電子郵件的方式聯繫幣趣,並透過電子郵件的方式和幣趣之間進行文件的傳輸（「正式溝通」）。您也可以非正式地透過電話聯繫幣趣（非正式溝通）。除非雙方有任何其他約定，否則任何正式溝通都應該使用英語進行。若任何英文版的溝通和非英文版的溝通不一致，則以英文版的溝通內容為準。若任何正式溝通或非正式溝通的內容與本協議的內容不一致，以本協議的內容為準。',
  'components.simple.x726be':
    '10.4如本協議任何條款被認定為無效、違法或不可執行，本協議其餘條款的有效性、合法性和可執行性均不應受任何形式的影響或削弱。但是，如果本協議的任何條款在任何司法管轄區的任何適用法律項下無效、違法或不可執行，則就該司法管轄區而言，該等條款應被視為相應修改以符合該法律的最低要求，或者，如果出於任何原因該等條款不被視為已修改，則該等條款僅在該無效性、違法性或對可執行性限制的範圍內無效、違法或不可執行，本協議中其餘條款的合法性、有效性或可執行性或該等條款在其他法域法律下的合法性、有效性或可執行性均不應受任何形式的影響。',
  'components.simple.34r56h':
    '10.5幣趣未有或延遲行使本協議項下任何權利或補救權不應視作放棄該等權利或補救權或構成對本協議的確認。幣趣對本協議的確認必須透過書面形式作出否則無效。任何一次或部份行使任何權利或補救不應防礙進一步或在其他情況行使該等權利，或行使任何其他權利或補救權。本協議下規定的權利和補救權均為附加性質，不排除法律規定的任何權利或補救。',
  'components.simple.8yus3r':
    '10.6本條款、您對本服務的使用、由本條款直接或間接引發的任何類型或性質的任何權利主張、反訴或爭議應受英格蘭和威爾斯法律管轄，並依其解釋，無需考慮其衝突法規則。',
  'components.simple.lo454v': '10.7 管轄和爭議解決',
  'components.simple.567hub':
    'a.您確認並同意，如果發生任何爭議、爭論、分歧或權利主張，包括本條款的存在、有效性、解釋、履行、違反或終止或由本條款引起的或與之相關的任何爭議（「爭議」），雙方應先根據屆時有效的《香港國際仲裁中心調解規則》將爭議提交香港國際仲裁中心（「HKIAC」）。',
  'components.simple.8gqyqi':
    'b.若在上述8.7條第(i)款中規定的調解請求提交後的九十（90）日內未透過簽署和解協議的方式解決爭議，則該等爭議應提交HKIAC，根據提交仲裁通知時有效的《香港國際仲裁中心機構仲裁規則》透過仲裁最終解決。本仲裁條款適用的法律應為中華人民共和國香港特別行政區（「香港」）法律。',
  'components.simple.v5k5kn':
    'c.您同意仲裁地為香港。仲裁人的人數應為三（3）名。幣趣應指定一（1）名仲裁員，您應指定一（1）名仲裁員。第三位仲裁員應由HKIAC的主席指定。該等仲裁員應自由選擇，各方在選擇仲裁員時不受任何指定名單的限制。仲裁程序應以英文進行。',
  'components.simple.pu2327':
    'd.您同意幣趣不應被要求提供文件的一般發現信息，而僅可被要求提供與爭議結果相關且對爭議結果有實質意義的特定的、經識別的文件。',
  'components.simple.w86687':
    'e.任何仲裁裁決應為終局裁決，對各方均具有約束力，並可在任何有管轄權的法院被強制執行。',
  'components.simple.bw64il':
    '10.8未經幣趣事先書面同意，您不得全部或部分轉讓、更新或讓本協議及本協議下的權利義務。幣趣有權透過向您發出書面通知的方式轉讓、更替或讓與本協議項下的任何權利或義務，該通知自送達之日起生效。',
  'components.simple.137yu5':
    '10.9本協議所有條款的標題僅為閱讀方便，本身並無實際涵義，不能作為本協議意義解釋的依據。',
  'components.simple.co7ivn':
    '10.10若本協議內容與其他語言的翻譯版本的內容有衝突，以英文版的本協議內容為準。',
  'components.simple.4i87f9': '10.11 本協議的最終解釋權歸幣趣所有。',
  'login.indexnew.e6y77j': '使用二維碼登錄',
  'login.indexnew.kv2529': '二維碼已失效',
  'login.indexnew.6dd1e5': '立即刷新',
  'login.indexnew.jk723f': '在App上確認',
  'login.indexnew.msql2b': '使用',
  'login.indexnew.1ls8gl': '幣趣手機App',
  'login.indexnew.4p37cx': '掃描二維碼',
  'login.index.518our': '獲取二維碼',
  'login.country.cpp4yr': '選擇區號',
  'login.country.o4efb0': '搜索',
  'login.country.dbb7i9': '選擇地區',
  'enforcement.index.glq4x4': '幣趣法務',
  'enforcement.index.j6do82':
    '我們的願景是解決加密貨幣和區塊鏈的設計挑戰，為下一階段的主流應用設定標準。',
  'enforcement.index.ns090u': '凍結令和披露令',
  'components.disclosure.c31xn2': '幣趣使用條款',
  'components.disclosure.7m8c62': '幣趣法庭命令門戶',
  'components.disclosure.qhl2v9': '（適用於凍結令/披露令）',
  'components.disclosure.7xrmo3': '請僅在持有法庭命令的情況下使用本頁面',
  'components.disclosure.dcq7c7':
    '歡迎訪問幣趣法律凍結令/披露令門戶。法定代表人可以使用該門戶，提交與幣趣官網加密貨幣交易平臺用戶賬戶相關的凍結令和披露令。請確保您的訂單與平臺用戶有關，而不是與單獨交易平臺有關',
  'components.disclosure.q5b601': '請在下頁的表格中填寫信息並提交證明文件。',
  'components.disclosure.j6h429':
    '由於該門戶與幣趣特定團隊關聯，因此只能用於提交最終凍結令和披露令。任何其他法庭命令或訴訟程序，都不應通過這個專用門戶提交，而是應該發送到幣趣相關實體的註冊辦事處。幣趣不會審查或接受通過此門戶送達的其他材料。',
  'components.disclosure.d71ku2':
    '請確保您提交的命令已確認收到，並且您已收到提交命令的參考信息。幣趣將審查成功提交的所有案例，但是如果您未檢查審查情況，幣趣概不負責。',
  'components.disclosure.23iexl':
    '相關命令最好只提交一次。重複提交只會拖延您案件的處理進程。',
  'components.disclosure.8nr8z1':
    '幣趣開通本門戶並非意在一一遵守提交的任何凍結令和披露令。幣趣凍結用戶ID（賬戶）的能力或者向第三方披露與用戶ID（賬戶）相關信息的能力，受適用法律和法規以及幣趣用戶權利（和幣趣對用戶的責任）約束。\r\n 幣趣將審查通過此門戶成功提交的任何凍結令或披露令以便確定其能否得到遵守執行。幣趣完全保留現在擁有的或可能擁有的反對任何凍結令或披露令的權利。',
  'components.disclosure.27z7cv':
    '根據涉及的法院和實體不同，幣趣還有權反對做出凍結令或披露令的相關法院的管轄權。幣趣開通該門戶供上傳特定命令，並不表明幣趣接受相關法院的管轄權，而且幣趣也不放棄已經擁有或可能擁有的反對相關法院管轄權的任何權利。',
  'components.disclosure.4gd7w7':
    '對於幣趣能夠遵守執行且無需聘請外部律師即可處理的凍結令和/或披露令，幣趣通常要求凍結令涉及的每位用戶ID（賬戶）支付$500的合理合規費用；同樣地，幣趣要求披露令涉及的每位用戶ID（賬戶）支付$500的合理合規費用。如果同一用戶ID（賬戶）在兩種命令或聯合命令中都有涉及，那麼幣趣與該用戶ID（賬戶）相關的合理合規費用則為$1,000。\r\n 使用該門戶提交凍結令和/或披露令，即表明您同意以上述規定為基礎向幣趣支付合理的合規費用（但不應影響幣趣根據己方絕對酌情決定權，認為有必要獲得外部法律建議時獲得已支付法律費用返還的權利）',
  'components.disclosure.3675e8':
    '我們同意此門戶網站的上述使用條款（我們可能在適當時間發布有關法律命令的指南）。',
  'components.disclosure.5nw311': '請同意免責聲明',
  'components.disclosure.13563x': '同意並提出請求',
  'components.formbox.ibm33u': '提交請求',
  'components.formbox.42c25u': '是',
  'components.formbox.6775e9':
    '目前，我們積壓了大量請求。為了加快問題解決速度，請不要重新提交凭證。同時，我們強烈建議您訪問幣趣常見問題頁面，快速找到答案：',
  'components.formbox.wh3bb8': '。非常感謝您的配合',
  'components.formbox.4j461y': '請選擇您的問題(法律)',
  'components.formbox.r716w9': '您用來接收回覆(以及任何披露信息)的電子郵件地址',
  'components.formbox.4ipunl': '您的姓名',
  'components.formbox.fz9724': '公司名稱',
  'components.formbox.5m129h': '法院所在國家/地區',
  'components.formbox.1t32m3': '簽發法院',
  'components.formbox.x5c5m4': '多方復審聽證會的答覆日期',
  'components.formbox.kl233z': '索賠編號/參考資料',
  'components.formbox.339ddn': '待凍結賬號(持有人姓名/用戶ID/賬號電子郵件)',
  'components.formbox.l9w4t3': '保密要求(是否可以通知用戶)',
  'components.formbox.e8n33x': '請輸入您的郵箱',
  'components.formbox.6fk7qq': '主題',
  'components.formbox.h6mhj9': '描述',
  'components.formbox.3qhc3v': '附件',
  'components.waiting.to28je':
    '您的資料已提交審核，審核結果將發送到您預留的郵箱，請保持關注。',
  'home.index.13w8x6': 'BasicEx 幣趣產品簡介',
  'home.index.sf95h6': '幣趣錢包',
  'home.index.916ckf':
    '安全便捷的多鏈錢包，您可一鍵完成 儲值 / 提現 / 買賣等操作，也可向幣趣錢包用戶免費轉帳/收款。',
  'home.index.yrsun1': '支援多條公鏈，例如：TRC20 / ERC20 / BSC / POLYGON。',
  'home.index.089fo6': '支援 USDT / ETH / BTC / TRX 等多種加密貨幣。',
  'home.index.r2831g': '開通錢包',
  'home.index.i886e8': '區塊鏈支付',
  'home.index.c372qd':
    '希望接受 USDT 支付的商家可以使用幣趣支付，通過幣趣提供的區塊鏈支付處理系統來促進這一過程。',
  'home.index.y3x6w2':
    '這是一種去中心化的支付平台，允許商家接受 USDT 交易。這類支付的主要好處之一是交易成本比傳統支付方式低得多，但還有許多其他好處。',
  'home.index.yvfa61':
    '一鍵快捷買賣加密貨幣，也可自選商家線上進行交易，註冊成為幣趣錢包用戶即刻享有。',
  'home.index.1n42v0': '幣趣C2C平臺提供擔保交易服務，以確保您的交易安全無憂。',
  'home.index.81h875':
    '支援多種 加密貨幣 / 法幣，無T+1限制，完成實名認證即可進行線上交易。',
  'home.index.g1p541': '一鍵購買',
  'home.index.323423': 'twPng',
  'localeAll.234asd': '請選擇儲值幣種',
  'components.header-info.j6esv2': '新手教程',
  'resigter.verification.w175qb': '驗證碼無效，請點擊重新獲取',
  'qrrouter.wxtipcom.8axip0': '點擊右上角選擇',
  'qrrouter.wxtipcom.2wm33j': '在瀏覽器中打開',
  'qrrouter.index.wvls92': '請使用幣趣App掃碼',
  'qrrouter.index.tt12fs': '正在打開幣趣App...',
  'qrrouter.index.id86f1': '打開幣趣App',
  'qrrouter.index.u63nz6': '如果您尚未安裝，請先下載',
  'qrrouter.index.4dxm5b': '下載幣趣App',
  'recharge.index.9z9w2e': '錢包地址',
  'recharge.index.2f8wh2': '請勿向合約地址充值，否則將無法找回',
  'payment.index.0n2eod': '{0} 張優惠券',
  'payment.index.6c163p': '優惠券',
  'payment.index.3uy4pw': '選擇優惠券',
  'payment.index.i15zz2': '滿',
  'payment.index.16l0wm': '可用',
  'payment.index.u7tek5': '到期',
  'payment.index.64iqff': '不可用',
  'payment.index.hkk670': '不使用優惠券',
  'payment.index.zd1b88': '查看優惠券記錄',
  'payment.index.59376r': '已使用',
  'payment.index.jbd8h6': '暫無記錄',
  'payment.index.17zzsj': '已過期',
  'payment.index.ww2c87': '暫無可用',
  'navbar.index.u61ns9': '網路不可用，請檢查您的網路設置。',
  'balance.coupon.1nmcyv': '未使用',
  'down.index.u22o39': '備用下載：',
  'down.index.w2bq03': '點擊下載',
  'down.index.Tutorial1': '添加H5到主屏幕,',
  'down.index.Tutorial2': '查看教程',
  'login.indexnew.uc1wu1': '信任當前設備',
  'login.indexnew.start': '開始時間',
  'login.indexnew.end': '結束時間',
  'login.indexnew.kown': '我知道了',
  'identityauthentication.idcard.2tyer4': '請上傳',
  'identityauthentication.idcard.ly8zq2': '證件的照片',
  'identityauthentication.idcard.4nn5ve':
    '請使用手機掃描二維碼，並按相關提示完成高級認證',
  'identityauthentication.idcard.ej24b3': '掃碼驗證',
  'identityauthentication.idcard.8fp6ul': '人臉識別',
  'identityauthentication.idcard.im974z': '請先上傳證件資訊',
  'transferaccounts.index.4cy1yh': '修改備註',
  'resigter.index.234dsd': '暫不支援該區域手機號碼，請改用電子郵件註冊。',
  'resigter.index.234dsa': '暫不支援該區域手機號碼',
  'resigter.index.234dsab': '其他',
  'tab.loc1': '語言和地區',
  'tab.loc2': '匯率',
  'moneywallet.flashhistory.q6rvgn': '閃兌歷史記錄',
  'moneywallet.flashhistory.98n2vu': '初始幣種',
  'moneywallet.flashhistory.452g9m': '初始金額',
  'moneywallet.flashhistory.5fx2j6': '兌換幣種',
  'moneywallet.flashhistory.yq7qj6': '兌換金額',
  'moneywallet.flashhistory.kf7611': '兌換匯率',
  'explain.index.9epet3': '重置',
  'localeAll.text499': `渠道合作夥伴`,
  'address.index.open': '白名單已開啟',
  'code.index.EMAIL': '請先獲取郵箱驗證碼',
  'code.index.PHONE': '請先獲取手機驗證碼',
};
