<template>
  <a-modal
    v-model:visible="openModal"
    :footer="false"
    :header="false"
    :closable="false"
  >
    <div class="searchBoxApp">
      <header>
        <span>{{ $t('address.index.pqk179') }}</span>
        <icon-close @click="() => (openModal = false)" />
      </header>
      <ul class="select-list">
        <li v-if="selectList.length > 0">
          <p class="title-text">{{
            search1 || $t('components.revence-data.2iv5ux')
          }}</p>
          <a-select
            v-model="form.status"
            style="width: 100%; height: 40px"
            :popup-visible="dropdownVisible"
            @click="toggleDropdown"
          >
            <a-option
              v-for="item in selectList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click="handleSelect(item)"
            />
          </a-select>
        </li>
        <li v-if="isTime">
          <p class="title-text">{{ $t('login.indexnew.start') }}</p>
          <div class="selectTimeApp">
            <input v-model="startTime" type="datetime-local" inputmode="none" />
          </div>
        </li>
        <li v-if="isTime">
          <p class="title-text">{{ $t('login.indexnew.end') }}</p>
          <div class="selectTimeApp">
            <input v-model="endTime" type="datetime-local" />
          </div>
        </li>
      </ul>
      <div class="selectRight">
        <a-button @click="handleReset('isGet')">{{
          $t('explain.index.9epet3')
        }}</a-button>
        <a-button type="primary" @click="handleToSelect">{{
          $t('fish.changefish.dteu66')
        }}</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
  import {
    ref,
    defineProps,
    defineEmits,
    reactive,
    toRefs,
    watch,
    onMounted,
  } from 'vue';
  import dayjs from 'dayjs';

  const props = defineProps({
    dialogAddVisible: {
      type: Boolean,
      default: false,
    },
    selectList: {
      type: Array<any>,
      default: () => {
        return [];
      },
    },
    search1: {
      type: String,
      default: '',
    },
    isTime: {
      type: Boolean,
      default: false,
    },
  });
  const refProps = toRefs(props);

  const form = reactive<any>({
    status: ' ',
    start: `${dayjs().format('YYYY-MM-DD')} 00:00:00`,
    end: `${dayjs().format('YYYY-MM-DD')} 23:59:59`,
  });
  const startTime = ref<any>(``);
  const endTime = ref<any>(``);

  const formDel = reactive<any>({ ...form });
  const emits = defineEmits([
    'update:dialogAddVisible',
    'resetOut',
    'successOut',
  ]);
  const openModal = ref(false);
  watch(refProps.dialogAddVisible, (val, old) => {
    openModal.value = val;
  });
  watch(openModal, (val, old) => {
    emits('update:dialogAddVisible', val); // 此处的update亦要写
  });

  const handleReset = (t: string) => {
    startTime.value = `${dayjs().format('YYYY-MM-DD')} 00:00`;
    endTime.value = `${dayjs().format('YYYY-MM-DD')} 23:59`;
    openModal.value = false;
    Object.assign(form, formDel);

    if (t === 'isGet') {
      emits('resetOut', form);
    }
  };

  onMounted(() => {
    handleReset('');
  });
  const handleToSelect = () => {
    if (startTime.value && endTime.value) {
      form.start = dayjs(startTime.value).format('YYYY-MM-DD HH:mm:ss');
      form.end = dayjs(endTime.value).format('YYYY-MM-DD HH:mm:ss');
      emits('successOut', form);
    }
  };

  const handleChangeForm = (start: any, end: any) => {
    if (start && end) {
      startTime.value = dayjs(start).format('YYYY-MM-DD HH:mm');
      endTime.value = dayjs(end).format('YYYY-MM-DD HH:mm');
    }
  };

  const dropdownVisible = ref(false); // 下拉框显示状态

  // 选择框点击事件，切换下拉框的显示状态
  const toggleDropdown = () => {
    dropdownVisible.value = !dropdownVisible.value;
  };

  // 选项点击事件，更新选中的值
  const handleSelect = (item: any) => {
    dropdownVisible.value = false;
    form.status = item.value;
  };

  defineExpose({
    handleChangeForm,
  });
</script>

<style lang="less" scoped>
  .searchBoxApp {
    width: @modalApp;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 550;
      margin-bottom: 10px;
    }
    .select-list {
      margin: 0;
      padding: 0;
      .selectTimeApp {
        display: flex;
        align-items: center;
        input {
          width: 100%;
          height: 40px;
          background: var(--color-fill-2);
          // border: 1px solid var(--color-neutral-3);
          padding: 0 12px;
        }
      }
    }
    .selectRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 40px 0 10px 0;
      button {
        width: 48%;
        height: 42px;
        border-radius: 5px;
      }
    }
  }
</style>
